import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import Dialog from "@mui/material/Dialog";

import {
  getMember,
  updateMember,
  getReservedFlights,
  getReservedFlightByRecord,
} from "api/Member";
import { createTikkie, getAllTikkieRequests } from "api/Payment";

import useApi from "hooks/useApi";
import {
  removeUserFromLocalStorage,
  getUserFromLocalStorage,
} from "hooks/LocalStorage";

import { checkISAdminOrEmployee } from "utils/Utils";

import ChangePassword from "components/change-password";
import ReservedFlightDetails from "components/reservedFlightDetails";
import "./style.css";

import TikkieTab from "./tabs/tikkie-tab";
import ActivitiesTab from "./tabs/activities-tab";
import ProfileTab from "./tabs/profile-tab";
import PassportManager from "./tabs/passport-manager";
import FlightDetails from "./tabs/flight-details";
import Reports from "./tabs/reports";
import Stripe from "./tabs/stripe";

const Account = ({}) => {
  const { tabActive } = useParams();
  var utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);

  const [tab, setTab] = useState(tabActive ?? "profile");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [open, setOpen] = useState(false);
  const [changePass, setChangePass] = useState(false);
  const [info, setInfo] = useState(false);

  const getMemberInfo = useApi(getMember);
  const getReservedFlightDetails = useApi(getReservedFlightByRecord);
  const getAllReservedFlights = useApi(getReservedFlights);
  const fetchAllTikkieRequests = useApi(getAllTikkieRequests);

  useEffect(() => {
    if (tab === "profile") {
      getMemberInfo.request();
    }
    if (tab === "my-activity") {
      getAllReservedFlights.request();
    }
    if (tab === "tikkie") {
      fetchAllTikkieRequests.request();
    }
  }, [getMemberInfo.error, tab]);

  const handleChange = (newValue) => {
    setTab(newValue);
    setSuccess("");
  };

  const handelClickOpen = (recordLocator) => {
    setOpen(true);
    getReservedFlightDetails.request({
      recordLocator,
    });
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required().label("First Name"),
    lastName: Yup.string().required().label("Last Name"),
    dateOfBirthDay: Yup.string().nullable().max(2).label("Date Of Birth"),
    dateOfBirthMonth: Yup.string().nullable().label("Date Of Birth"),
    dateOfBirthYear: Yup.string().min(4).max(4).label("Date Of Birth"),
    phoneNumber: Yup.string().required().label("Phone Number"),
    email: Yup.string().email().required().label("Email"),
    passportNumber: Yup.string().label("Passport Number"),
  });

  const validationSchemaForTikkie = Yup.object().shape({
    description: Yup.string()
      .required()
      .max(35, "Description should not be more than 35 characters")
      .label("Description"),
    amountInCents: Yup.string().required().label("Amount"),
    expiryDate: Yup.string().nullable().label("Expiration Date"),
  });

  const monthsLong = {
    January: "01",
    February: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12",
  };

  const handelSubmit = async (values) => {
    setLoading(true);
    setError("");
    setSuccess("");
    const body = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      dateOfBirth:
        (values.dateOfBirthYear ??
          dayjs(getMemberInfo?.data?.memberDetails?.dateOfBirth).format(
            "YYYY"
          )) +
        "-" +
        (values.dateOfBirthMonth
          ? monthsLong[values.dateOfBirthMonth]
          : dayjs(getMemberInfo?.data?.memberDetails?.dateOfBirth).format(
              "MM"
            )) +
        "-" +
        (values.dateOfBirthDay ??
          dayjs(getMemberInfo?.data?.memberDetails?.dateOfBirth).format("DD")),

      phoneNumber:
        values.phoneNumber.charAt(0) === "+"
          ? values.phoneNumber
          : "+" + values.phoneNumber,
      passportNumber: values.passportNumber,
    };
    const res = await updateMember({
      body,
    });
    if (!res.ok) {
      setError(res?.data?.errorDetails);
    } else {
      setSuccess("Your account updated successfully!");
    }
    setLoading(false);
  };

  const handleCreateTikkie = async (values) => {
    setLoading(true);
    const amountInCents = Number(
      (parseFloat(values.amountInCents) * 100).toFixed(0)
    );
    const body = {
      amountInCents,
      description: values.description,
      expiryDate: values.expiryDate
        ? dayjs(values.expiryDate).format("YYYY-MM-DD")
        : dayjs(
            new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000)
          ).format("YYYY-MM-DD"),
      referenceId: uuidv4()?.slice(6),
      email: getMemberInfo?.data?.memberDetails?.email,
    };
    const response = await createTikkie({ body });
    if (!response.ok) {
      setError("There is something wrong. Please try again later!");
    } else {
      const oldTikkieRequests = [
        ...fetchAllTikkieRequests?.data?.myPaymentRequests,
      ];
      if (navigator.clipboard) {
        navigator.clipboard.writeText(response?.data?.url);
      }

      const dataObj = {
        myPaymentRequests: oldTikkieRequests,
      };

      oldTikkieRequests.unshift(response?.data);
      fetchAllTikkieRequests?.setData(dataObj);
      setSuccess(`Your Tikkie request created successfully!`);
      setInfo("The created tikkie link was successfully copied to clipboard!");
    }
    setLoading(false);
  };

  const handleShowPassword = () => {
    setChangePass(true);
  };

  const tabs = [
    { label: "My Account", value: "profile", permission: "user" },
    { label: "My Activities", value: "my-activity", permission: "user" },
    { label: "Tikkie", value: "tikkie", permission: checkISAdminOrEmployee() },
    { label: "Stripe", value: "stripe", permission: checkISAdminOrEmployee() },
    {
      label: "Passport Manager",
      value: "passport-manager",
      permission: checkISAdminOrEmployee(),
    },
    {
      label: "PNR Manager",
      value: "pnr-manager",
      permission:
        checkISAdminOrEmployee() ||
        getUserFromLocalStorage()?.type === "B_AGENT",
    },
    {
      label: "Reports",
      value: "reports",
      permission: getUserFromLocalStorage()?.type === "ADMIN",
    },
  ];

  const renderTab = () => {
    const allTabs = {
      profile: changePass ? (
        <ChangePassword setChangePass={setChangePass} />
      ) : (
        <ProfileTab
          handelSubmit={handelSubmit}
          validationSchema={validationSchema}
          error={error}
          success={success}
          handleShowPassword={handleShowPassword}
          loading={loading}
          getMemberInfo={getMemberInfo?.data?.memberDetails}
        />
      ),
      "my-activity": (
        <ActivitiesTab
          getAllReservedFlights={getAllReservedFlights}
          handelClickOpen={handelClickOpen}
        />
      ),

      tikkie: (
        <TikkieTab
          error={error}
          success={success}
          handleSubmit={handleCreateTikkie}
          validationSchema={validationSchemaForTikkie}
          loading={loading}
          fetchAllTikkieRequests={fetchAllTikkieRequests}
          info={info}
        />
      ),

      stripe: <Stripe />,
      "passport-manager": <PassportManager />,
      "pnr-manager": <FlightDetails />,
      reports: <Reports />,
    };

    return allTabs[tab];
  };

  return (
    <>
      <div className="bg account-page">
        <Container>
          {getMemberInfo.error ? (
            <Box py={7}>
              <div className="empty-data-msg">
                <h1 className="medium-font-size title-header">
                  The session has expired, please relogin!
                </h1>
              </div>
            </Box>
          ) : (
            <Box py={7}>
              <div className="card-card">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  {getMemberInfo?.loading ? (
                    <>
                      <Box display="flex" alignItems="center" flexWrap="wrap">
                        <Skeleton
                          variant="circular"
                          width={100}
                          height={100}
                          animation="wave"
                          sx={{ bgcolor: "#F1F3F6", marginBottom: 1 }}
                        />
                        <Box ml={2}>
                          <Skeleton
                            variant="text"
                            width={90}
                            height={30}
                            animation="wave"
                            sx={{ bgcolor: "#F1F3F6", marginBottom: 1 }}
                          />
                          <Skeleton
                            variant="text"
                            width={40}
                            height={30}
                            animation="wave"
                            sx={{ bgcolor: "#F1F3F6", marginBottom: 1 }}
                          />
                        </Box>
                      </Box>
                      <Box>
                        <Skeleton
                          variant="text"
                          width={110}
                          height={30}
                          animation="wave"
                          sx={{ bgcolor: "#F1F3F6", marginBottom: 1 }}
                        />
                        <Skeleton
                          variant="text"
                          width={110}
                          height={30}
                          animation="wave"
                          sx={{ bgcolor: "#F1F3F6", marginBottom: 1 }}
                        />
                      </Box>
                      <Box>
                        <Skeleton
                          variant="text"
                          width={110}
                          height={30}
                          animation="wave"
                          sx={{ bgcolor: "#F1F3F6", marginBottom: 1 }}
                        />
                        <Skeleton
                          variant="text"
                          width={110}
                          height={30}
                          animation="wave"
                          sx={{ bgcolor: "#F1F3F6", marginBottom: 1 }}
                        />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box display="flex" alignItems="center" my={1}>
                        <Avatar
                          sx={{
                            width: 114,
                            height: 114,
                            background: "var(--primary-color)",
                            fontSize: 55,
                          }}
                        >
                          {getMemberInfo?.data?.memberDetails?.firstName?.charAt(
                            0
                          )}
                          {getMemberInfo?.data?.memberDetails?.lastName?.charAt(
                            0
                          )}
                        </Avatar>
                        <Box mx={2}>
                          <p className="bold main-title m-0">
                            {getMemberInfo?.data?.memberDetails?.firstName +
                              " "}
                            {getMemberInfo?.data?.memberDetails?.lastName}
                          </p>
                          <p className="medium-font-size m-0">
                            Member ID:
                            {" " + getMemberInfo?.data?.memberDetails?.memberId}
                          </p>
                          <p className="medium-font-size m-0">
                            Member Since:
                            {" " +
                              getMemberInfo?.data?.memberDetails?.memberSince}
                          </p>
                        </Box>
                      </Box>

                      <Box mx={2}>
                        <p className="medium-font-size gray">
                          Available points
                        </p>
                        <p className="bold main-title m-0">
                          {getMemberInfo?.data?.memberDetails?.travelPoints}
                        </p>
                      </Box>
                      <Box mx={2}>
                        <p className="medium-font-size gray">Current Status</p>
                        <p className="bold main-title m-0">
                          {getMemberInfo?.data?.memberDetails?.status}
                        </p>
                      </Box>
                    </>
                  )}
                </Box>
              </div>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                position="relative"
              >
                <Box overflow="auto" className="airports-tabs">
                  {tabs?.map(
                    (item) =>
                      item.permission && (
                        <p
                          key={item.value}
                          onClick={() => handleChange(item.value)}
                          className={`job-title  medium-font-size ${
                            tab == item.value ? "active" : "inactive"
                          } `}
                        >
                          {item.label}
                        </p>
                      )
                  )}
                </Box>
              </Box>
              {getMemberInfo?.loading ? (
                <div className="card-card d-flex flex-wrap">
                  <Box>
                    <Skeleton
                      variant="text"
                      width={210}
                      height={30}
                      animation="wave"
                      sx={{ bgcolor: "#F1F3F6", marginBottom: 1 }}
                    />
                    <Skeleton
                      variant="text"
                      width={210}
                      height={30}
                      animation="wave"
                      sx={{ bgcolor: "#F1F3F6", marginBottom: 1 }}
                    />
                  </Box>
                  <Box mx={1}>
                    <Skeleton
                      variant="text"
                      width={210}
                      height={30}
                      animation="wave"
                      sx={{ bgcolor: "#F1F3F6", marginBottom: 1 }}
                    />
                    <Skeleton
                      variant="text"
                      width={210}
                      height={30}
                      animation="wave"
                      sx={{ bgcolor: "#F1F3F6", marginBottom: 1 }}
                    />
                  </Box>
                </div>
              ) : (
                <div className="card-card">
                  {/* {tab === 0 ? (
                    changePass ? (
                      <ChangePassword setChangePass={setChangePass} />
                    ) : (
                      <ProfileTab
                        handelSubmit={handelSubmit}
                        validationSchema={validationSchema}
                        error={error}
                        success={success}
                        handleShowPassword={handleShowPassword}
                        loading={loading}
                        getMemberInfo={getMemberInfo?.data?.memberDetails}
                      />
                    )
                  ) : tab == 1 ? (
                    <ActivitiesTab
                      getAllReservedFlights={getAllReservedFlights}
                      handelClickOpen={handelClickOpen}
                    />
                  ) : tab == 2 ? (
                    <TikkieTab
                      error={error}
                      success={success}
                      handleSubmit={handleCreateTikkie}
                      validationSchema={validationSchemaForTikkie}
                      loading={loading}
                      fetchAllTikkieRequests={fetchAllTikkieRequests}
                      info={info}
                    />
                  ) : tab === "stripe" ? (
                    <Stripe />
                  ) : tab === 3 ? (
                    <PassportManager />
                  ) : tab === 4 ? (
                    <FlightDetails />
                  ) : (
                    <Reports />
                  )} */}

                  {renderTab()}
                </div>
              )}
            </Box>
          )}
        </Container>
      </div>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog-container"
      >
        <Box p={4}>
          <ReservedFlightDetails
            loading={getReservedFlightDetails?.loading}
            reservation={getReservedFlightDetails?.data?.reservation}
            price={getReservedFlightDetails?.data?.reservation?.price}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default Account;
