import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import valid from "card-validator";

import { LabelAndValue } from "components/ui";
import Button from "components/button";
import AppDialog from "components/dialog";
import useApi from "hooks/useApi";

import { hotelPayment, hotelSell, getOffer } from "api/Hotels";

import TravelersInfo from "./steps/traveler-info";
import PaymentBlock from "./steps/payment";
import { Card } from "@mui/material";

const steps = ["Travellers Information", " Payment information"];

const Payment = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);

  const { state } = useLocation();

  const [error, setError] = useState(false);
  const fetchGetOffer = useApi(getOffer);

  const [guests, setGuests] = useState([]);

  const offerId = state?.hotelDetailsAndRoom?.room?.id;

  useEffect(() => {
    fetchGetOffer.request({ offerId });
  }, []);

  const handleMultiElements = async (values) => {
    setGuests(values.guests);
    setActiveStep(1);
  };

  const handleHotelSell = async (values) => {
    setError(false);

    setLoading(true);
    const body = {
      data: {
        type: "hotel-order",
        guests,
        travelAgent: {
          contact: {
            email: "shivabhaggan@gmail.com",
          },
        },
        roomAssociations: [
          {
            guestReferences: guests.map((_, index) => ({
              guestReference: index + 1 + "",
            })),
            hotelOfferId: offerId,
          },
        ],
        payment: {
          paymentCard: {
            paymentCardInfo: {
              vendorCode: valid.number(values.cardNumber)?.card
                ? valid
                    .number(values.cardNumber)
                    ?.card.type?.substring(0, 2)
                    .toUpperCase()
                : "VI",
              holderName: values.holderName,
              cardNumber: values.cardNumber,
              securityCode: values.securityId,
              expiryDate: values.expiryDate.replace("/", ""),
            },
          },
          method: "CREDIT_CARD",
        },
      },
    };

    const response = await hotelPayment({
      body,
    });
    if (!response.ok) {
      setModalOpen(true);
      setTimeout(() => navigate("/"), 1200);
    } else {
      {
        navigate("/hotelv2-confirm", {
          state: {
            hotelDetailsAndRoom: state?.hotelDetailsAndRoom,
            hotelSellResponse: response?.data,
          },
        });
      }
    }
    setLoading(false);
  };

  const TextSkeleton = () => (
    <Skeleton
      variant="text"
      width={60}
      height={20}
      sx={{
        bgcolor: "#F1F3F6",
        borderRadius: "4px",
      }}
      animation="wave"
    />
  );

  const onClose = () => {
    setModalOpen(false);
  };

  return (
    <div className="reservation">
      {fetchGetOffer.error ? (
        <Box
          minHeight={400}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography>
            There is something wrong, Please try again later!
          </Typography>
        </Box>
      ) : (
        <Box className="reservation-content">
          <Box sx={{ width: "100%" }} className="reservation-column-stepper">
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (
                <Step
                  onClick={() => {
                    if (index) {
                      setActiveStep(index);
                    }
                  }}
                  key={label}
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          {activeStep === 0 ? (
            <TravelersInfo
              loading={loading}
              loadingHotel={fetchGetOffer.loading}
              handleMultiElements={handleMultiElements}
              hotel={fetchGetOffer.data?.data}
            />
          ) : (
            <PaymentBlock
              loading={fetchGetOffer.loading}
              handleHotelSell={handleHotelSell}
            />
          )}
          <aside>
            <Box className="card-card" mt={10}>
              <p className="medium-font-size d-flex gap-2">
                Hotel :
                {fetchGetOffer?.loading ? (
                  <TextSkeleton />
                ) : (
                  fetchGetOffer.data?.data?.hotel?.name
                )}
              </p>

              <Box mt={2}>
                <p>
                  {" "}
                  <strong>Room:</strong>{" "}
                  {
                    fetchGetOffer.data?.data?.offers?.[0]?.room?.typeEstimated
                      ?.beds
                  }{" "}
                  Bed
                  {
                    fetchGetOffer.data?.data?.offers?.[0]?.room?.typeEstimated
                      ?.bedType
                  }
                </p>
              </Box>
              <Box mt={2}>
                <p class="d-flex gap-2">
                  <strong>Check-in:</strong>
                  {fetchGetOffer?.loading ? (
                    <TextSkeleton />
                  ) : (
                    fetchGetOffer.data?.data?.offers?.[0]?.checkInDate || "-"
                  )}
                </p>
                <p class="d-flex gap-2">
                  {" "}
                  <strong>Check-Out:</strong>{" "}
                  {fetchGetOffer?.loading ? (
                    <TextSkeleton />
                  ) : (
                    fetchGetOffer.data?.data?.offers?.[0]?.checkOutDate || "-"
                  )}
                </p>
              </Box>
            </Box>
            <div className="card-card">
              <p className="medium-font-size pb-2">Price details</p>
              <hr />

              <LabelAndValue
                label="Price"
                value={
                  fetchGetOffer?.loading ? (
                    <TextSkeleton />
                  ) : (
                    <>
                      {fetchGetOffer.data?.data?.offers?.[0]?.price?.currency}
                      {fetchGetOffer.data?.data?.offers?.[0]?.price?.total ||
                        "-"}
                    </>
                  )
                }
                mb={2}
              />

              <LabelAndValue
                mt={2}
                sx={{ "& p": { fontSize: "1rem", fontWeight: "bold" } }}
                label="Total"
                value={
                  fetchGetOffer?.loading ? (
                    <TextSkeleton />
                  ) : (
                    <>
                      {fetchGetOffer.data?.data?.offers?.[0]?.price?.currency +
                        " "}
                      {fetchGetOffer.data?.data?.offers?.[0]?.price?.total ||
                        "-"}
                    </>
                  )
                }
              />
            </div>
          </aside>
        </Box>
      )}
      <AppDialog open={isModalOpen} onClose={onClose}>
        <Typography variant="h6" color="error">
          Failed Reservation
        </Typography>
        <Typography mb={3}>
          Failed to make reservation please contact Satyam Tours B.V
        </Typography>
        <Button className="w-100" onClick={onClose}>
          Close
        </Button>
      </AppDialog>
    </div>
  );
};

export default Payment;
