import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import * as Yup from "yup";
import Modal from "@mui/material/Modal";
import LinearProgress from "@mui/material/LinearProgress";
import { getUserFromLocalStorage } from "hooks/LocalStorage";

import PassengerDetails from "./steps/passengerDetails";
import SeatsMap from "./steps/seatsMap";
import Overview from "./steps/overview";

import useApi from "hooks/useApi";
import { getMember } from "api/Member";
import { flightReverse, seatMap, getValidateFlight } from "api/Flights";
import { CurrencyContext } from "../useContext";

import BannerInput from "components/bannerInput";

import { countries } from "assets/data/countries";

import { checkISAdminOrEmployee } from "utils/Utils";

import "./style.css";

//----------------------------------------------------------------

const steps = ["Search", "Passenger Details", "Seat Map", "Overview & Payment"];

const Booking = ({}) => {
  let navigate = useNavigate();
  const { state } = useLocation();

  const [openPop, setOpenPop] = useState(false);

  const [currency, setCurrency] = useContext(CurrencyContext);
  const [selected, setSelected] = useState(1);
  const [errMsg, setErrorMsg] = useState("");
  const [checked, setChecked] = useState(false);
  const [seats, setSeats] = useState([]);
  const [code, setCode] = useState();
  const [activeStep, setActiveStep] = useState(1);
  const [paymentType, setPaymentType] = useState();
  const [expand, setExpand] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [reservedSeats, setReservedSeats] = useState([]);
  const [count, setCount] = useState(0);
  const [errModalMsg, setErrModalMsg] = useState(false);
  const [overviewData, setOverViewDate] = useState();
  const [values, setValues] = useState();
  const [travelers, setTraverls] = useState([]);
  const [reverseSeats, setReverseSeats] = useState([]);
  const [travelerIndex, setTravelerIndex] = useState(0);
  const getMemberInfo = useApi(getMember);
  const [adjustedPrice, setAdjustedPrice] = useState();

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setTravelerIndex(0);
  };

  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  let travelersInit = {};
  let validateTravelers = {};

  const countryCodes = countries.map((country) => country.code); // Extracting country codes from the countries array

  state?.flight?.travelerPricings?.map((item, index) => {
    const initialValues = {
      ["firstName" + index]:
        index === 0 ? getMemberInfo?.data?.memberDetails?.firstName : "",
      ["lastName" + index]:
        index === 0 ? getMemberInfo?.data?.memberDetails?.lastName : "",

      ["dateOfBirthDay" + index]:
        index === 0 && getMemberInfo?.data?.memberDetails?.dateOfBirth
          ? dayjs(getMemberInfo?.data?.memberDetails?.dateOfBirth).format("DD")
          : "",
      ["dateOfBirthMonth" + index]:
        index === 0 && getMemberInfo?.data?.memberDetails?.dateOfBirth
          ? dayjs(getMemberInfo?.data?.memberDetails?.dateOfBirth).format("MM")
          : "",
      ["expDateDay" + index]: "",
      ["expDateMonth" + index]: "",
      ["expDateYear" + index]: "",
      ["docNumber" + index]: "",
      ["countryCallingCode" + index]: "",
      ["documents" + index]: "",
      ["dateOfBirthYear" + index]:
        index === 0 && getMemberInfo?.data?.memberDetails?.dateOfBirth
          ? dayjs(getMemberInfo?.data?.memberDetails?.dateOfBirth).format(
              "YYYY"
            )
          : "",
      ["gender" + index]: "",
      ...(item.travelerType === "ADULT" && {
        ["emailAddress" + index]:
          state?.source === "NDC"
            ? "satyam@satyamholidays.net"
            : index === 0
            ? getMemberInfo?.data?.memberDetails?.email
            : "",
      }),
      ...(item.travelerType === "ADULT" && {
        ["number" + index]: "",
      }),
    };
    const validateValues = {
      ["firstName" + index]: Yup.string()
        .matches(/^[a-zA-Z\s]*$/g, "Should contain just letters")
        .required()
        .label("First Name"),
      ["lastName" + index]: Yup.string()
        .required()
        .matches(/^[a-zA-Z\s]*$/g, "Should contain just letters")
        .label("Last Name"),
      ["expDateDay" + index]: Yup.number()
        .nullable()
        .max(31)
        .required()
        .label("Expiration Date"),
      ["expDateMonth" + index]: Yup.string()
        .nullable()
        .required()
        .label("Expiration Passport Date "),
      ["expDateYear" + index]: Yup.string()
        .nullable()
        .required("Expiration Passport Date is required")
        .test(
          "is-valid-expiration-date",
          "Expiration Passport Date is invalid!",
          (value, context) => {
            const deDate =
              state?.flight?.itineraries[0]?.segments[0]?.departure?.at;

            // Check if departure date is available
            if (!deDate) {
              return context.createError({
                message:
                  "Departure date is missing or unavailable. Please check the flight details.",
              });
            }

            // Access sibling fields for day and month
            const day = context.parent["expDateDay" + index];
            const month = context.parent["expDateMonth" + index];

            if (!value || !day || !month) {
              return context.createError({
                message:
                  "Expiration date fields are incomplete. Please provide all date details.",
              });
            }

            // Construct the expiration date using day, month, and year
            const expirationDate = dayjs(
              `${month}-${day}-${value}`,
              "MM-DD-YYYY",
              true
            );
            const departureDate = dayjs(deDate);

            // Check if the expiration date is valid
            if (!expirationDate.isValid()) {
              return context.createError({
                message:
                  "Invalid expiration date format. Please check the date.",
              });
            }

            // Ensure expiration date is at least 6 months after departure date
            return expirationDate.isAfter(departureDate.add(6, "months"));
          }
        ),

      ["docNumber" + index]: Yup.string().label("Document Number"),
      ["countryCallingCode" + index]: Yup.string(),

      ["documents" + index]: Yup.array()

        .of(
          Yup.object().shape({
            nationality: Yup.string().required().label("Nationality"),
            issuanceCountry: Yup.string()
              .required()
              .test(
                "match-country-code",
                "Invalid Issuance Country",
                (value) => {
                  return countryCodes.includes(value);
                }
              )
              .label("Issuance Country"),
          })
        )
        .when('["documents" + index]', {
          is: (documents) => documents && documents.length > 0,
          then: Yup.array().required("Documents are required"),
        }),

      ["dateOfBirthDay" + index]: Yup.number()
        .nullable()
        .max(31)
        .required()
        .label("Date Of Birth"),
      ["dateOfBirthMonth" + index]: Yup.string()
        .nullable()
        .required()
        .label("Date Of Birth"),
      ["dateOfBirthYear" + index]: Yup.string()
        .min(4)
        .max(4)
        .required()
        .label("Date Of Birth"),
      ["gender" + index]: Yup.string().required().label("gender"),
      ...(item.travelerType !== "CHILD" &&
        item.travelerType !== "HELD_INFANT" && {
          ["emailAddress" + index]: Yup.string()
            .email()
            .required()
            .label("Email"),
        }),
      ...(item.travelerType !== "CHILD" &&
        item.travelerType !== "HELD_INFANT" && {
          ["number" + index]: Yup.string()
            .required()
            .matches(phoneRegExp, "Phone number is invalid")
            .label("Phone Number"),
        }),
    };
    travelersInit = { ...travelersInit, ...initialValues };
    validateTravelers = { ...validateTravelers, ...validateValues };
  });

  const validationSchema = Yup.object().shape(validateTravelers);

  const monthsLong = {
    January: "01",
    February: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12",
  };

  const handelInfoSubmit = (values) => {
    setValues(values);
    setActiveStep(2);

    navigate("/booking/seats-map", {
      state,
    });

    window.scrollTo(0, 0);
    const travelersData = [];
    state?.flight?.travelerPricings?.map((item, index) => {
      const userContact = {
        ...(item.travelerType !== "CHILD" &&
          item.travelerType !== "HELD_INFANT" && {
            contact: {
              emailAddress: values["emailAddress" + index],
              phones: [
                {
                  countryCallingCode: values[
                    "countryCallingCode" + index
                  ].replace("+", ""),
                  deviceType: "MOBILE",
                  number: values["number" + index].replace("+", ""),
                },
              ],
            },
          }),
        ...(values["documents" + index] && {
          documents: values["documents" + index],
        }),
        dateOfBirth:
          values["dateOfBirthYear" + index] +
          "-" +
          (values["dateOfBirthMonth" + index]?.length === 2
            ? values["dateOfBirthMonth" + index]
            : monthsLong[values["dateOfBirthMonth" + index]]) +
          "-" +
          ((values["dateOfBirthDay" + index] + "").length === 1
            ? "0" + values["dateOfBirthDay" + index]
            : values["dateOfBirthDay" + index]),
        gender: values["gender" + index].toUpperCase(),
        id: index + 1,
        name: {
          firstName: values["firstName" + index].toUpperCase(),
          lastName: values["lastName" + index].toUpperCase(),
        },
      };
      travelersData.push(userContact);
    });

    setTraverls(travelersData);
    const travelersId = [];
    travelersData?.map((i) => {
      const obj = {
        [i.id]: {
          seats: [],
        },
      };
      travelersId.push(obj);
    });

    setReverseSeats(travelersId);
  };

  //Flight Offer
  const handelSubmit = async () => {
    setErrModalMsg(false);
    setOpenPop(true);

    let flightInfo = state.flight;

    flightInfo.travelerPricings.map((segment, index) => {
      segment.fareDetailsBySegment?.map((fare) => {
        if (reverseSeats[index][segment?.travelerId]) {
          const getSeatNum = reverseSeats[index][
            segment?.travelerId
          ]?.seats?.find((i) => i?.segmentId === fare.segmentId);
          if (getSeatNum) {
            fare.additionalServices = {
              chargeableSeatNumber: getSeatNum?.selectedSeat,
            };
          } else {
            delete fare.additionalServices;
          }
        }
      });
    });

    const body = {
      schema: state?.ndcResponse ?? flightInfo,
      ...(state?.officeId && { officeId: state?.officeId }),
      isPrivateFare: false,
    };

    const res = await getValidateFlight({
      body,
      amaRefId: state?.amaRefId,
    });

    if (!res.ok) {
      setErrModalMsg(res?.data?.errorDetails?.errors);
      setTimeout(() => {
        setOpenPop(false);
      }, 4000);
    } else {
      setOverViewDate(res?.data?.response);
      setActiveStep(3);
      navigate("/booking/overview", {
        state,
      });
      window.scrollTo(0, 0);
      setOpenPop(false);
    }
  };

  // Order Create
  const handelReverseSubmit = async () => {
    setErrModalMsg(false);
    setOpenPop(true);

    let editGrandPrice = overviewData.data.flightOffers[0];
    editGrandPrice.price.grandTotal =
      getTotalPrice() ?? state?.flight?.price?.total;
    const body = {
      travelers,
      schema: state?.ndcResponse ?? editGrandPrice,
      adjustedPrice:
        adjustedPrice ??
        (travelers?.length > 0 && reverseSeats?.length > 0
          ? getTotalPrice()
          : state.priceWithFare),
      ...(state?.officeId && { officeId: state?.officeId }),
    };

    const flightReverseResponse = await flightReverse({
      body,
      amaRefId: state?.amaRefId,
    });

    if (!flightReverseResponse.ok) {
      setErrModalMsg(flightReverseResponse?.data?.errorDetails?.errors);

      setTimeout(() => {
        setOpenPop(false);
        navigate(-3);
      }, 4000);
    } else {
      setOpenPop(false);

      const overviewData = state?.flight;
      overviewData.travelers =
        flightReverseResponse?.data?.response?.data.travelers;
      navigate("/confirmation", {
        state: {
          overview: flightReverseResponse?.data?.response,
          travelerPricing: state?.flight?.travelerPricings,
          dictionaries: state?.dictionaries,
          fareAdjustment: state?.fareAdjustment,
          prevPath: state?.prevPath,
          reverseSeats: reverseSeats,
          getTotalPrice: getTotalPrice(),
          tikkieUrl: flightReverseResponse?.data?.tikkieUrl,

          ...(state?.officeId && { officeId: state?.officeId }),
        },
      });

      window.scrollTo(0, 0);
    }
  };

  const handelSeatMap = async () => {
    setLoading(true);

    const body = {
      schema: state?.ndcResponse ?? state?.flight,
      ...(state?.officeId && { officeId: state?.officeId }),
    };

    const res = await seatMap({
      body,
      amaRefId: state?.amaRefId,
    });

    if (!res.ok) {
      setErrorMsg(
        "Seat map is currently not available from the airline, please select a seat at the airport while checking-in."
      );
    } else {
      setSeats(res?.data?.response?.data);
      const allSegments = [];
      state.flight?.itineraries?.map((i) =>
        i?.segments?.map((seg) => {
          let arr = seg?.departure?.iataCode + seg?.arrival?.iataCode;
          arr = [];
          allSegments.push(arr);
        })
      );

      setReservedSeats(allSegments);
    }
    setLoading(false);
  };

  const handelTraverlsSeats = (selectedSeat, price, checkIfExist) => {
    if (checkIfExist) {
      let indexTra;
      travelers?.map((traveler, index) => {
        reverseSeats[index][traveler?.id]?.seats?.map((i) => {
          if (
            i?.segmentId === seats[value]?.segmentId &&
            selectedSeat === i?.selectedSeat
          ) {
            indexTra = index;
          }
        });
      });

      handelRemoveSeat(selectedSeat, indexTra);
    } else {
      if (travelerIndex < travelers?.length - 0) {
        let data = [...reverseSeats];

        let getTraverlSeats =
          data[travelerIndex][travelers[travelerIndex].id].seats;

        const editSegmant = getTraverlSeats?.find(
          (i) => i.segmentId === seats[value]?.segmentId
        );
        if (editSegmant) {
          if (
            travelers.length === 1 ||
            state?.flight?.travelerPricings?.filter(
              (trav) => trav?.travelerType !== "HELD_INFANT"
            )?.length === 1
          ) {
            editSegmant.selectedSeat = selectedSeat;
            editSegmant.price = price;
          } else {
            return false;
          }
        } else {
          const seat = {
            segmentId: seats[value]?.segmentId,
            selectedSeat,
            price,
          };
          getTraverlSeats.push(seat);
        }

        data[travelerIndex][travelers[travelerIndex].id].seats =
          getTraverlSeats;
        setReservedSeats(data);
        setCount(count + 1);
        if (
          travelerIndex <
          state?.flight?.travelerPricings?.filter(
            (trav) => trav?.travelerType !== "HELD_INFANT"
          )?.length -
            1
        ) {
          setTravelerIndex(travelerIndex + 1);
        }
      }
    }
  };

  const handelRemoveSeat = (selectedSeat, index) => {
    let data = [...reservedSeats];

    const editSegmant = data[index][travelers[index].id].seats?.filter(
      (i) => i.selectedSeat !== selectedSeat
    );

    data[index][travelers[index].id].seats = editSegmant;
    setReservedSeats(data);
    setCount(count + 1);
    setTravelerIndex(index);
  };

  const getTotalPrice = () => {
    let arr = [];

    travelers?.map((traveler, index) => {
      reverseSeats[index][traveler?.id]?.seats?.map((i) => {
        const price = i?.price ? (i?.price === "FREE" ? 0 : +i?.price) : 0;
        arr.push(price);
      });
    });
    const Total =
      +state?.priceWithFare * 1 + arr.reduce((total, num) => total + num, 0);

    return Total.toFixed(2);
  };

  const getAllReversedSeats = () => {
    const arr = [];
    travelers?.map((traveler, index) => {
      reverseSeats[index][traveler?.id]?.seats?.map((i) => {
        if (i?.segmentId === seats[value]?.segmentId) {
          arr.push(i?.selectedSeat);
        }
      });
    });

    return arr;
  };

  useEffect(() => {
    if (activeStep === 1 && getUserFromLocalStorage()?.type === "MEMBER") {
      getMemberInfo.request();
    }
    if (activeStep === 2 && count === 0) {
      handelSeatMap();
    }
  }, [activeStep, count]);

  const getPriceForTraveler = (price) => {
    let editedPrice = price;
    if (state?.fareAdjustment) {
      editedPrice = (
        price * 1 +
        state?.fareAdjustment / state?.flight?.travelerPricings?.length
      ).toFixed(2);
    }

    return editedPrice;
  };

  return (
    <div className="reservation">
      <div className="reservation-content">
        <Box sx={{ width: "100%" }} className="reservation-column-stepper">
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step
                onClick={() => {
                  if (index === 0) {
                    navigate(-(1 + activeStep - 1));
                  }
                  if (activeStep > index) {
                    setActiveStep(index);
                  }
                }}
                key={label}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <main>
          <h1 className="heading-title">
            {state?.flightDetails?.from}
            <span>
              <svg
                className="Icon__StyledIcon-sc-1det6wr-0 fJfkpT"
                viewBox="0 0 24 24"
                preserveAspectRatio="xMidYMid meet"
              >
                <path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path>
              </svg>
            </span>
            {state?.flightDetails?.to}

            {state?.flightDetails?.return === "Return" && " and  back"}
          </h1>
          {activeStep === 1 && (
            <PassengerDetails
              state={state}
              travelersInit={travelersInit}
              validationSchema={validationSchema}
              handelInfoSubmit={handelInfoSubmit}
              setCode={setCode}
              source={state?.source}
            />
          )}
          {activeStep === 2 && (
            <SeatsMap
              loading={loading}
              errMsg={errMsg}
              value={value}
              travelers={travelers}
              seats={seats}
              reverseSeats={reverseSeats}
              handelRemoveSeat={handelRemoveSeat}
              handelTraverlsSeats={handelTraverlsSeats}
              getAllReversedSeats={getAllReversedSeats}
              handelSubmit={handelSubmit}
              setTravelerIndex={setTravelerIndex}
              handleChangeTab={handleChangeTab}
              state={state}
              travelerIndex={travelerIndex}
              currency={currency?.sign}
            />
          )}

          {activeStep === 3 && (
            <Overview
              overviewData={overviewData}
              state={state}
              travelers={travelers}
              paymentType={paymentType}
              handelReverseSubmit={handelReverseSubmit}
              setPaymentType={setPaymentType}
            />
          )}
        </main>
        <aside className="reversation-sideBar-wrapper">
          <div className="reversation-bill">
            <div className="reversation-bill-box">
              <div className="reversation-bill-box-items">
                <div className="reversation-bill-item">
                  <p>Number of Passengers</p>

                  <p>{state?.flight?.travelerPricings?.length}</p>
                </div>
                {state?.flight?.travelerPricings?.map((traveler, index) => (
                  <div className="reversation-bill-item" key={index}>
                    <p>{traveler?.travelerType}&nbsp; Passenger</p>

                    <p>
                      {currency?.sign}

                      {getPriceForTraveler(traveler?.price?.total)}
                    </p>
                  </div>
                ))}

                {travelers?.length > 0 &&
                  reverseSeats?.length > 0 &&
                  travelers?.map(
                    (traveler, index) =>
                      state?.flight?.travelerPricings[index]?.travelerType !==
                        "HELD_INFANT" && (
                        <div className="reversation-bill-item" key={index}>
                          <p>
                            {traveler?.travelerType}Seat for{" "}
                            {traveler?.name?.firstName}.
                            {traveler?.name?.lastName?.charAt(0)}
                          </p>
                          <p>
                            {currency?.sign}
                            {reverseSeats[index][traveler?.id]?.seats
                              ?.map((i) =>
                                i?.price === "FREE" ? 0 : +i?.price
                              )
                              ?.reduce((total, num) => total + num, 0)

                              .toFixed(2)
                              .toString()}
                          </p>
                        </div>
                      )
                  )}

                <hr />
              </div>

              <div className="reversation-bill-box-finel-item mb-1">
                <p>Total ({currency?.currency})</p>
                <p>
                  {currency?.sign}
                  {travelers?.length > 0 && reverseSeats?.length > 0
                    ? getTotalPrice()
                    : state?.priceWithFare}
                </p>
              </div>
              {checkISAdminOrEmployee() && activeStep === 3 && (
                <Box
                  className="px-4"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    "& .input-wapper": {
                      height: "34px !important",
                      width: "80px",
                    },
                  }}
                >
                  <label>Selling For</label>

                  <Box display="flex" alignItems="center">
                    {" "}
                    <label className="mx-2">{currency?.sign}</label>
                    <BannerInput
                      defaultValue={
                        travelers?.length > 0 && reverseSeats?.length > 0
                          ? getTotalPrice()
                          : state?.priceWithFare
                      }
                      type="number"
                      onChange={(e) => setAdjustedPrice(e.target.value)}
                    />
                  </Box>
                </Box>
              )}
            </div>
          </div>
        </aside>
      </div>
      <div
        className={`box-fix ${expand ? "expand" : "no-expand"}`}
        onClick={() => setExpand(!expand)}
      >
        <div className="d-flex align-items-center justify-content-between mb-1">
          <p>Number of Passengers</p>

          <p>{state?.flight?.travelerPricings?.length}</p>
        </div>
        {state?.flight?.travelerPricings?.map((traveler, index) => (
          <div
            key={index}
            className="d-flex align-items-center justify-content-between mb-1"
          >
            <p>{traveler?.travelerType}&nbsp; Passenger</p>

            <p>
              {currency?.sign}

              {getPriceForTraveler(traveler?.price?.total)}
            </p>
          </div>
        ))}
        {travelers?.length > 0 &&
          reverseSeats?.length > 0 &&
          travelers?.map(
            (traveler, index) =>
              state?.flight?.travelerPricings[index]?.travelerType !==
                "HELD_INFANT" && (
                <div
                  key={index}
                  className="d-flex align-items-center justify-content-between mb-1"
                >
                  <p>
                    {traveler?.travelerType}Seat for {traveler?.name?.firstName}
                    .{traveler?.name?.lastName?.charAt(0)}
                  </p>
                  <p>
                    {currency?.sign}
                    {reverseSeats[index][traveler?.id]?.seats
                      ?.map((i) => (i?.price === "FREE" ? 0 : +i?.price))
                      ?.reduce((total, num) => total + num, 0)
                      .toFixed(2)
                      .toString()}
                  </p>
                </div>
              )
          )}
        <hr />
        <div className="d-flex align-items-center justify-content-between mt-2">
          <p>Total ({currency?.currency})</p>
        </div>
        <p className="box-price" onClick={() => setExpand(!expand)}>
          {currency?.sign}
          {travelers?.length > 0 && reverseSeats?.length > 0
            ? getTotalPrice()
            : state?.priceWithFare}
        </p>
      </div>
      <Modal
        open={openPop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="pop-up-modal">
          {errModalMsg ? (
            <>
              <p className="main-title error-msg-modal">
                Sorry, There is something wrong! Please try again
              </p>

              {errModalMsg?.map((err, index) => (
                <p style={{ marginTop: 4 }} key={index}>
                  {err?.title} :{err?.detail}
                </p>
              ))}
            </>
          ) : (
            <>
              <p className="main-title loading-title">
                Hold on, while your reservation is in progress
              </p>
              <LinearProgress sx={{ backgroundColor: "#edcfa7" }} />
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};
export default Booking;
