import { useFormikContext } from "formik";
import { useState, useEffect } from "react";

import dayjs from "dayjs";
import PassportField from "./upload-passport/input-field";

import { monthsLong } from "utils/Utils";

const ImageInput = ({ index, setErrImg, departureDate, hasProgress }) => {
  const { setFieldValue, setFieldError, values, errors } = useFormikContext();

  const [score, setScore] = useState();

  useEffect(() => {
    if (
      values[`documents${index}`] &&
      values[`documents${index}`]?.length > 0
    ) {
      const documents = values[`documents${index}`];
      documents[0].expiryDate =
        values["expDateYear" + index] +
        "-" +
        (values["expDateMonth" + index]?.length === 2
          ? values["expDateMonth" + index]
          : monthsLong[values["expDateMonth" + index]]) +
        "-" +
        values["expDateDay" + index];
      setFieldValue(`documents${index}`, documents);
    }
  }, [values]);

  const handlePassportResponse = (travelerData) => {
    const date = travelerData?.birthDate?.split("-");
    const documents = [
      {
        number: travelerData?.documentNumber,
        expiryDate: travelerData?.expirationDate,
        issuanceCountry: travelerData?.issuingState,
        nationality: travelerData?.nationality,
        documentType: "PASSPORT",
        holder: true,
      },
    ];
    setFieldValue(`firstName${index}`, travelerData?.firstName);
    setFieldValue(`lastName${index}`, travelerData?.lastName);
    setFieldValue(`gender${index}`, travelerData?.sex?.toUpperCase());
    if (travelerData?.birthDate) {
      setFieldValue(`dateOfBirthYear${index}`, date[0]);
      setFieldValue(`dateOfBirthMonth${index}`, date[1]);
      setFieldValue(`dateOfBirthDay${index}`, date[2]);
    }

    setScore(travelerData?.score);

    setFieldValue(
      `expDateDay${index}`,
      dayjs(travelerData?.expirationDate).format("DD")
    );
    setFieldValue(
      `expDateMonth${index}`,
      dayjs(travelerData?.expirationDate).format("MM")
    );
    setFieldValue(
      `expDateYear${index}`,
      dayjs(travelerData?.expirationDate).format("YYYY")
    );
    setFieldValue(`docNumber${index}`, travelerData?.documentNumber);
    setFieldValue(`documents${index}`, documents);

    let isValid = dayjs(travelerData?.expirationDate).diff(
      dayjs(departureDate),
      "month"
    );

    if (isValid < 6) {
      setFieldError(
        `expDateYear${index}`,
        "Expiration Passport Date must be at least 6 months after the departure date!"
      );
    }
  };

  const segments = [
    { color: "#ff3d00", show: score >= 25 || score <= 25 }, // Bright Red segment
    { color: "#ff9100", show: score >= 25 }, // Vibrant Orange segment
    { color: "#ffeb3b", show: score >= 50 }, // Bright Yellow segment
    { color: "#4caf50", show: score >= 100 }, // Lime Green segment
  ];

  return (
    <>
      <PassportField
        setErrImg={setErrImg}
        handlePassportResponse={handlePassportResponse}
      />

      {hasProgress && score && (
        <div className="progress-bar">
          {segments.map(
            (segment, index) =>
              segment.show && (
                <div
                  key={index}
                  className="progress-bar-segment"
                  style={{
                    width: `25%`,
                    backgroundColor: segment.color,
                  }}
                />
              )
          )}
        </div>
      )}
    </>
  );
};

export default ImageInput;
