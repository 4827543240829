import { Menu, MenuItem, MenuButton, FocusableItem } from "@szhsin/react-menu";

import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import Radio from "@mui/material/Radio";

import { IoIosArrowDown } from "react-icons/io";
import { BiCheck } from "react-icons/bi";
import { ImUser } from "react-icons/im";

import { ReactComponent as Children } from "assets/images/children.svg";
import { ReactComponent as Infants } from "assets/images/infants.svg";
import { ReactComponent as Cabinbaggage } from "assets/images/cabinbaggage.svg";
import { ReactComponent as Checkedbaggage } from "assets/images/checkedbaggage.svg";
import { ReactComponent as Passenger } from "assets/images/passenger.svg";
import ElderlyIcon from "@mui/icons-material/Elderly";

import ListItem from "../listItem";
import "./style.css";
import Button from "../button";

export default function DropDown({
  activeOption,
  options,
  multiple,
  onClick,
  activeTab,
  title,
  hideArrow,
  options2,
  onClickMinus,
  onClickPlus,
  checkedBag,
  onChangeRadio,
  hideFilter,
  setActiveTab,
  className,
  redirectSearch,
}) {
  return (
    <Menu
      className="main-dropdown"
      menuButton={
        <MenuButton className={`dropdown-btn ${className}`}>
          {activeOption ? (
            activeOption
          ) : title ? (
            title
          ) : (
            <div className="passengers">
              <Passenger />
              <span>{options?.reduce((i, a) => i + a.num, 0)}</span>
              {!hideFilter && activeTab === "flights" && (
                <>
                  <Cabinbaggage />
                  <span>Checked</span>
                </>
              )}
            </div>
          )}

          {!hideArrow && <IoIosArrowDown />}
        </MenuButton>
      }
      transition
    >
      {activeTab !== "flights" && options?.length > 0 ? (
        options.map((item, index) => (
          <MenuItem
            className="dropDown-item"
            key={index}
            onClick={() => onClick(item)}
          >
            {((multiple && activeOption.includes(item.value)) ||
              activeOption === item.value) && <BiCheck />}
            {item.value}
          </MenuItem>
        ))
      ) : (
        <>
          <div className="passenger-container p-3">
            <h2>Passengers</h2>
            <div className="mb-24">
              {options.map((item, index) => (
                <ListItem
                  key={index}
                  index={index}
                  title={item.value}
                  subTitle={item.subTitle}
                  Icon={
                    index === 0
                      ? ElderlyIcon
                      : index === 1
                      ? ImUser
                      : index === 2
                      ? Children
                      : Infants
                  }
                  num={item.num}
                  onClickMinus={() => onClickMinus(index, "pass")}
                  onClickPlus={() => onClickPlus(item.value, "pass")}
                  pass
                />
              ))}
            </div>
            {!hideFilter && activeTab === "flights" && (
              <>
                <h2>Bags</h2>
                <div className="mb-24 d-flex align-items-center justify-content-between ">
                  <div>
                    <Checkedbaggage /> Checked baggage{" "}
                  </div>
                  <Radio checked={checkedBag} onClick={onChangeRadio} />
                </div>
              </>
            )}
          </div>
          <MenuItem className="d-block footer-drop">
            <hr />
            <div className="d-flex justify-content-between">
              <Button>Cancel</Button>
              <Button className="primary-color" onClick={redirectSearch}>
                Done
              </Button>
            </div>
          </MenuItem>
        </>
      )}
    </Menu>
  );
}
