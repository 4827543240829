import React, { useEffect, useState } from "react";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import BannerInput from "components/bannerInput";
import PhoneNumber from "components/phoneInput";
import Button from "components/button";
import { TextField, MenuItem } from "@mui/material";

function TravelersInfo({ handleMultiElements, loading, hotel, loadingHotel }) {
  const validationSchema = Yup.object().shape({
    guests: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().required().label("First Name"),
        lastName: Yup.string().required().label("Last Name"),
        email: Yup.string().email().required().label("Email"),
        phone: Yup.string().required().label("Phone"),
        title: Yup.string().required().label("Title"),
      })
    ),
  });

  const [guests, setGuests] = useState([]);

  useEffect(() => {
    if (hotel) {
      const adults = Array.from({
        length: +hotel?.offers?.[0]?.guests?.adults,
      }).map((guest) => ({ type: "adult" }));
      const childAges = hotel?.offers?.[0]?.guests?.childAges
        ? hotel?.offers?.[0]?.guests?.childAges.map((age) => ({
            type: "child",
            age,
          }))
        : [];

      setGuests([...adults, ...childAges]);
    }
  }, [hotel]);

  const initialGuests = guests.map((item, index) => ({
    tid: index + 1,
    firstName: "",
    lastName: "",
    email: "",
    title: "",
    phone: "",
    ...(item.type === "child" && { childAge: item.age }),
  }));

  return (
    <div className="pt-4">
      <p className="bolder main-title">
        Secure booking — only takes 2 minutes!
      </p>
      <Formik
        initialValues={{ guests: initialGuests }}
        validationSchema={validationSchema}
        onSubmit={handleMultiElements}
        enableReinitialize
      >
        {({
          handleChange,
          handleSubmit,
          errors,
          touched,
          values,
          setFieldValue,
        }) => (
          <>
            <div className="card-card">
              <p className="sub-main-title bold mb-2">Who's checking in?</p>

              <FieldArray name="guests">
                {() =>
                  values?.guests.map((guest, index) => (
                    <div key={index} className="form-inputs mb-4">
                      <p>
                        <strong>
                          {index + 1}- Guest{" "}
                          {guest.childAge
                            ? "Child " + guest.childAge + " Age"
                            : "Adult"}{" "}
                        </strong>
                      </p>
                      <Box
                        mt={2}
                        display="flex"
                        flexWrap={{ xs: "wrap", md: "nowrap" }}
                        gap={2}
                      >
                        <Box sx={{ width: "144px" }}>
                          <label>
                            <span className="mandatory-sign">*</span> Title
                          </label>
                          <TextField
                            onChange={(e) =>
                              setFieldValue(
                                `guests[${index}].title`,
                                e.target.value
                              )
                            }
                            name={`guests[${index}].title`}
                            select
                            variant="outlined"
                            sx={{
                              boxShadow: "rgb(186 199 213) 0px 0px 0px 1px",
                              borderRadius: "3px",
                              height: "42px",
                              width: "100%",
                              fontSize: "14px",
                              mb: 2,
                            }}
                          >
                            <MenuItem value="MR">Mr</MenuItem>
                            <MenuItem value="MS">Ms</MenuItem>
                            <MenuItem value="MRS">Mrs</MenuItem>
                          </TextField>
                        </Box>

                        <BannerInput
                          placeholder="First Name"
                          label="First Name"
                          onChange={(e) =>
                            setFieldValue(
                              `guests[${index}].firstName`,
                              e.target.value
                            )
                          }
                          error={
                            touched.guests?.[index]?.firstName &&
                            errors.guests?.[index]?.firstName
                          }
                          name={`guests[${index}].firstName`}
                          mandatory
                        />
                        <BannerInput
                          placeholder="Last Name"
                          label="Last Name"
                          onChange={(e) =>
                            setFieldValue(
                              `guests[${index}].lastName`,
                              e.target.value
                            )
                          }
                          error={
                            touched.guests?.[index]?.lastName &&
                            errors.guests?.[index]?.lastName
                          }
                          name={`guests[${index}].lastName`}
                          mandatory
                        />
                      </Box>
                      <Box
                        display="flex"
                        flexWrap={{ xs: "wrap", md: "nowrap" }}
                        gap={2}
                      >
                        <BannerInput
                          placeholder="Email"
                          label="Email"
                          onChange={(e) =>
                            setFieldValue(
                              `guests[${index}].email`,
                              e.target.value
                            )
                          }
                          error={
                            touched.guests?.[index]?.email &&
                            errors.guests?.[index]?.email
                          }
                          name={`guests[${index}].email`}
                          mandatory
                        />
                        <PhoneNumber
                          mandatory
                          label="Phone Number"
                          onChange={handleChange(`guests[${index}].phone`)}
                          name={`guests[${index}].phone`}
                          error={
                            touched.guests?.[index]?.phone &&
                            errors.guests?.[index]?.phone
                          }
                        />
                      </Box>
                    </div>
                  ))
                }
              </FieldArray>
            </div>
            <Button
              onClick={handleSubmit}
              type="submit"
              className="bigBtn primary-color booking"
              loading={loading}
              disabled={loadingHotel}
            >
              Place reservation
            </Button>
          </>
        )}
      </Formik>
    </div>
  );
}

export default TravelersInfo;
