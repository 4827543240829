import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import "./style.css";
import Iconify from "components/iconify";

//----------------------------------------------------------------
const Feature = ({ header, body, buttonValue, icon }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "80px",
          height: "80px",
          borderRadius: "50%",
          backgroundColor: "rgba(240,81,18,0.16)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 2,
        }}
      >
        <Iconify icon={icon} color="#f15e22" width="35px" />
      </Box>

      <Typography variant="h6">{header}</Typography>
      <Typography
        variant="body1"
        maxWidth={"350px"}
        color="text.disabled"
        mt={1}
      >
        {body}
      </Typography>
      {/* <Link to="/contact-us">
        <button className="btn global-btn">{buttonValue}</button>
      </Link> */}
    </Box>
  );
};

export default Feature;
