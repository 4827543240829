import { Suspense, useState } from "react";
import { Routes, Route } from "react-router-dom";

import HomePage from "pages/home";
import Search from "pages/search";
import Booking from "pages/booking";
import MyTrips from "pages/myTrip";
import Visa from "pages/visa";
import JobDetails from "pages/jobDetails";
import BookingInfo from "pages/bookingInfo";
import Hotels from "pages/hotels/Hotels";
import HotelDetails from "pages/hotels/Hotel-details";
import HotelPayment from "pages/hotels/payment";
import HotelConfirmation from "pages/hotels/confirmation";
import Account from "pages/account";
import AboutUs from "pages/about-us";
import ContactUs from "pages/contact-us";
import InitializeKiosk from "pages/initialize-kiosk";
import HowWeHire from "pages/how-we-hire";
import PrivacyPolicy from "pages/privacy-policy";
import EntryFee from "pages/entry-fee";
import FAQ from "pages/FAQ";
import FlightSchedules from "pages/flightSchedules";
import Jobs from "pages/jobs";
import HotelsV2 from "pages/hotelsV2/list";
import HotelsV2Details from "pages/hotelsV2/details";
import HotelsV2Payment from "pages/hotelsV2/payment";
import HotelsV2Confirm from "pages/hotelsV2/confirmation";
import ResetPassword from "pages/reset-password";
import CarDetails from "pages/cars/car-details";
import CarPayment from "pages/cars/payment";
import CarConfirmation from "pages/cars/confirmation";
import NotFoundPage from "pages/404";
import { CustomRouter, customHistory } from "./custom-router";

import Header from "components/header";
import Dialog from "components/dialog";
import Login from "components/login";
import SignUp from "components/signUp";

// const AboutUs = lazy(() => import("pages/about-us"));
// const ContactUs = lazy(() => import("pages/contact-us"));
// const InitializeKiosk = lazy(() => import("pages/initialize-kiosk"));
// const HowWeHire = lazy(() => import("pages/how-we-hire"));
// const PrivacyPolicy = lazy(() => import("pages/privacy-policy"));
// const EntryFee = lazy(() => import("pages/entry-fee"));
// const FAQ = lazy(() => import("pages/FAQ"));
// const  = lazy(() => import("pages/account"));
// const FlightSchedules = lazy(() => import(""));
// const Jobs = lazy(() => import("pages/jobs"));

export const AppRouter = ({ setClientSecret, setOpenCheckout }) => {
  const [signUpModal, setSignUpModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [redirectLink, setRedirectLink] = useState();

  const handleClose = () => {
    setSignUpModal(false);

    window.location.hash = "";
  };

  const handelOpenSignUp = () => {
    setSignUpModal(true);
  };

  return (
    <CustomRouter history={customHistory}>
      <Header
        handelOpenSignUp={handelOpenSignUp}
        handleCloseSignUp={handleClose}
      />

      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<div>LOADING ....</div>}>
              <HomePage setRedirectLink={setRedirectLink} />
            </Suspense>
          }
        />

        <Route
          path="/search"
          element={<Search setRedirectLink={setRedirectLink} />}
        />

        <Route path="/forgot-password/:token" element={<ResetPassword />} />
        <Route path="/visa/:countryName" element={<Visa />} />
        <Route path="/trips" element={<MyTrips />} />
        <Route path="/booking/:step" element={<Booking />} />
        <Route path="/jobs" element={<Jobs />} />

        <Route path="/job/:jobId" element={<JobDetails />} />

        <Route
          path="/entry-fee"
          element={
            <EntryFee
              setClientSecret={setClientSecret}
              setOpenCheckout={setOpenCheckout}
            />
          }
        />

        <Route path="/flight-schedules" element={<FlightSchedules />} />
        <Route
          path="/confirmation"
          element={
            <BookingInfo
              setClientSecret={setClientSecret}
              setOpenCheckout={setOpenCheckout}
            />
          }
        />
        <Route
          path="/my-account/:tabActive"
          element={<Account refresh={refresh} />}
        />
        <Route path="/hotels" element={<Hotels />} />
        <Route path="/hotel-details" element={<HotelDetails />} />
        <Route path="/hotel-payment" element={<HotelPayment />} />
        <Route path="/hotel-confirmation" element={<HotelConfirmation />} />

        <Route path="/hotelv2" element={<HotelsV2 />} />
        <Route path="/hotelv2-details" element={<HotelsV2Details />} />
        <Route path="/hotelv2-payment" element={<HotelsV2Payment />} />
        <Route path="/hotelv2-confirm" element={<HotelsV2Confirm />} />

        {/* <Route path="/cars" element={<CarList />} />
        <Route path="/car-details" element={<CarDetails />} />
        <Route path="/car-payment" element={<CarPayment />} />
        <Route path="/car-confirmation" element={<CarConfirmation />} /> */}

        <Route
          path="/about-us"
          element={
            <Suspense fallback={<div>LOADING ....</div>}>
              <AboutUs />
            </Suspense>
          }
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/initialize-kiosk" element={<InitializeKiosk />} />
        <Route path="/how-we-hire" element={<HowWeHire />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>

      <Login
        handelOpenSignUp={handelOpenSignUp}
        setRefresh={setRefresh}
        redirectLink={redirectLink}
      />

      <Dialog open={signUpModal} onClose={handleClose}>
        <SignUp handleClose={handleClose} />
      </Dialog>
    </CustomRouter>
  );
};
