import React, { useEffect, useState } from "react";
import { DateRangePicker, Calendar } from "react-date-range";
import { format } from "date-fns";
import dayjs from "dayjs";
import { isEqual } from "lodash";
import { Typography, Box } from "@mui/material";

import moment from "moment";
import Button from "../button";

import { daysDifference } from "utils/Utils";

import "./style.css";
import Badge from "components/badge";

const ContainerSearch = ({
  toggleDrawer,
  isMobile,
  setdepartureDate,
  setReturnDate,
  onHide,
  disabled,
  returnDate,
  departureDate,
  show,
  setShow,
  onSelect,
  flightType,
  dateDifference,
}) => {
  const [focusedRange, setFocus] = useState([0, 0]);
  const [daysFlight, setDaysFlight] = useState("");

  const [dateRange, setDatesRange] = useState([
    {
      startDate: departureDate ? moment.utc(departureDate).toDate() : null,
      endDate: returnDate ? moment.utc(returnDate).toDate() : new Date(""),
      key: "selection",
    },
  ]);

  const handleSelect = (ranges) => {
    setDaysFlight("");
    const startDate = moment.utc(ranges[0].startDate).toDate();
    const endDate = moment.utc(ranges[0].endDate).toDate();
    setDatesRange(ranges);
    setdepartureDate(startDate);
    setReturnDate(endDate);

    onSelect({
      startDate,
      endDate,
    });

    if (isEqual(focusedRange, [0, 1])) {
      toggleDrawer(false);

      setShow(null);
    }
  };

  useEffect(() => {
    if (show === "dateTo" && departureDate) {
      setFocus([0, 1]);
    }
  }, [show]);

  const renderDaysDiff = daysDifference(
    dateRange[0]?.startDate,
    daysFlight || dateRange[0]?.endDate
  ) &&
    !disabled && (
      <Badge
        className="teal mb-0"
        title={daysDifference(
          dateRange[0]?.startDate,
          daysFlight || dateRange[0]?.endDate
        )}
      />
    );

  return (
    <div
      className={`container-search calendar-container  ${
        isMobile && "res-calendar"
      }`}
    >
      {isMobile ? (
        <div className="date-picker-container">
          {!disabled ? (
            <DateRangePicker
              rangeColors={["#f15e22"]}
              ranges={dateRange}
              onChange={(item) => handleSelect([item.selection])}
              showSelectionPreview={false}
              moveRangeOnFirstSelection={false}
              months={12}
              scroll={false}
              minDate={new Date()}
              preventSnapRefocus="disabled"
              className="date-range-mobile"
              startDatePlaceholder="Departure Date: Anytime"
              endDatePlaceholder="Return Date: Anytime"
              initialFocusedRange={[0, 0]}
              focusedRange={focusedRange}
              showMonthAndYearPickers={false}
              onRangeFocusChange={(event) => {
                if (show === "dateTo") {
                  setFocus([0, 1]);
                } else {
                  setFocus(event);
                }
              }}
              dayContentRenderer={(day) => {
                return (
                  <span
                    onMouseEnter={() => {
                      if (isEqual(focusedRange, [0, 1])) {
                        setDaysFlight(day);
                      }
                    }}
                  >
                    {moment(day).format("DD")}
                  </span>
                );
              }}
            />
          ) : (
            <Calendar
              date={departureDate}
              onChange={(date) => {
                toggleDrawer(false);
                setdepartureDate(date);
              }}
              color={"#f15e22"}
              minDate={
                flightType === "Multi-city"
                  ? moment(departureDate).subtract(dateDifference, "d").toDate()
                  : moment().toDate()
              }
            />
          )}
        </div>
      ) : !disabled ? (
        <DateRangePicker
          rangeColors={["#f15e22"]}
          ranges={dateRange}
          onChange={(item) => handleSelect([item.selection])}
          showSelectionPreview={false}
          moveRangeOnFirstSelection={false}
          retainEndDateOnFirstSelection={false}
          direction="horizontal"
          dragSelectionEnabled={false}
          months={2}
          minDate={new Date()}
          startDatePlaceholder="Departure Date: Anytime"
          endDatePlaceholder="Return Date: Anytime"
          focusedRange={focusedRange}
          preventSnapRefocus="disabled"
          showMonthAndYearPickers={false}
          onRangeFocusChange={(event) => {
            if (show === "dateTo") {
              setFocus([0, 1]);
            } else {
              setFocus(event);
            }
          }}
          dayContentRenderer={(day) => {
            return (
              <span
                onMouseEnter={() => {
                  if (isEqual(focusedRange, [0, 1])) {
                    setDaysFlight(day);
                  }
                }}
              >
                {moment(day).format("DD")}
              </span>
            );
          }}
        />
      ) : (
        <Calendar
          date={departureDate}
          onChange={(date) => {
            setdepartureDate(date);
            setShow(null);
          }}
          color={"#f15e22"}
          minDate={
            flightType === "Multi-city"
              ? moment(departureDate).subtract(dateDifference, "d").toDate()
              : moment().toDate()
          }
        />
      )}

      <hr />
      <div className="btns-wrapper">
        <Button onClick={isMobile ? toggleDrawer : onHide}>Cancel</Button>
        {renderDaysDiff}

        <Button
          onClick={isMobile ? toggleDrawer : onHide}
          className="primary-color"
        >
          Set Date
        </Button>
      </div>
    </div>
  );
};

export default ContainerSearch;
