import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import IconButton from "@mui/material/IconButton";

import Slide from "assets/images/hotel-placeholder.jpg";

import Badge from "components/badge";
import { Typography } from "@mui/material";

//----------------------------------------------------------------

const HotelCard = ({
  hotel,
  infoSource,
  startDate,
  endDate,
  destination,
  guestCount,
  children,
  roomStayCandidate,
}) => {
  const navigate = useNavigate();
  const hotelDetails = {
    destination,
    startDate,
    endDate,
    children,
    guestCount,
    roomStayCandidate,
    infoSource,
    ...hotel,
  };

  const navigateToHotelDetails = () => {
    window.scrollTo(0, 0);
    navigate("/hotelv2-details", { state: { hotelDetails } });
  };

  return (
    <>
      <Box
        onClick={navigateToHotelDetails}
        sx={{
          bgcolor: "#fff",
          cursor: "pointer",
          "&:hover": { boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" },
        }}
        mb={2}
        display="flex"
        borderRadius="10px"
        overflow="hidden"
        flexDirection={{ xs: "column	", md: "row" }}
      >
        <Box
          position="relative"
          sx={{
            "& img": { width: { xs: "100%", md: "220px", height: "150px" } },
          }}
        >
          <IconButton sx={{ position: "absolute" }}>
            <FavoriteBorderIcon sx={{ color: "#fff" }} />
          </IconButton>

          <img src={hotel?.hotelImage || Slide} />
        </Box>
        <Box p={1} flex={1} display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box>
              <Typography className="bolder sub-main-title ">
                {hotel?.name} ({hotel?.cityCode})
              </Typography>
              {/* <p>{hotel?.address?.hotelCityName} </p>
              <p>{hotel?.address?.hotelAddressLine} </p> */}
            </Box>
          </Box>
          <Box
            mt={"auto"}
            display="flex"
            justifyContent="space-between"
            alignItems="end"
            flexWrap="wrap"
          >
            {/* <Box>
              {hotel?.basicProperty?.hotelAmenities?.map((amenity, index) => (
                <p key={index}>{amenity}</p>
              ))}

              <p className="d-flex medium-font-size gray mt-2">
                <p className="bolder me-1 medium-font-size">9\10</p> Very Good
                (2232) reviews{" "}
              </p>
            </Box> */}
            <Box
              display="flex"
              alignItems={{ xs: "flex-start", md: "flex-end" }}
              flexDirection="column"
            >
              {/* <Badge title="We have 3 left at" className="m-0 teal" /> */}

              <Typography className="bolder sub-main-title mt-1 ">
                {hotel?.offers?.[0]?.price?.currency}{" "}
                {hotel?.offers?.[0]?.price?.total}
              </Typography>

              {hotel?.pricing?.priceBeforeTax && (
                <>
                  <p className="small-font-size">
                    {hotel?.pricing?.currency} {hotel?.pricing?.priceAfterTax}{" "}
                    total
                  </p>
                  <p className="small-font-size">includes tax and fees</p>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HotelCard;
