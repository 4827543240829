import React, { useState, useEffect } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { useLocation } from "react-router-dom";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import IconButton from "@mui/material/IconButton";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from "@mui/material/Tooltip";

import { CurrencyContext } from "../useContext";
import confetti from "canvas-confetti";
import { getPaymentOptions } from "api/Payment";
import { LabelAndValue } from "components/ui";

import { checkISAdminOrEmployee } from "utils/Utils";
import useApi from "hooks/useApi";

import Badge from "components/badge";
import RoomCard from "./components/RoomCard";
import { Divider } from "@mui/material";

const HotelsConfirmation = () => {
  const SECONDS = 5;
  var end = Date.now() + SECONDS * 1000;

  var colors = ["#f15e22", "#124786"];
  const [copy, setCopy] = useState(false);

  const { state } = useLocation();

  useEffect(() => {
    (function frame() {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors,
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors,
      });
      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
  }, []);

  return (
    <div className="reservation">
      <div className="booking-info reservation-content">
        <div>
          <div className="booking-info-wrapper card-card">
            <span className="circle">
              <CheckIcon className="check-icon" />
            </span>
            <div>
              <p className="medium-font-size  mb-12 gray">
                Congratulations! your hotel booking is confirmed.
              </p>
              <div className="d-flex align-items-center">
                <p className="medium-font-size gray">
                  Reference Number:{" "}
                  <strong>
                    {
                      state?.hotelSellResponse?.associatedRecords?.[0]
                        ?.reference
                    }
                  </strong>
                </p>
                <CopyToClipboard
                  text={
                    state?.hotelSellResponse?.associatedRecords?.[0]?.reference
                  }
                  onCopy={() => setCopy(true)}
                >
                  <Tooltip title={copy ? "Copied!" : "Copy"}>
                    <IconButton>
                      <CopyAllIcon />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </div>
            </div>
          </div>

          <p className="medium-font-size mb-16">Hotel Details</p>
          <div className="card-card">
            <LabelAndValue
              label="Hotel Name: "
              value={state?.hotelSellResponse?.hotelBookings?.[0]?.hotel?.name}
              justifyContent="flex-start"
            />

            <LabelAndValue
              label="Hotel Id: "
              value={
                state?.hotelSellResponse?.hotelBookings?.[0]?.hotel?.hotelId
              }
              justifyContent="flex-start"
            />
            <LabelAndValue
              label="Confirmation Number: "
              value={
                state?.hotelSellResponse?.hotelBookings?.[0]
                  ?.hotelProviderInformation?.[0]?.confirmationNumber
              }
              justifyContent="flex-start"
            />
          </div>

          <p className="medium-font-size mb-16">Travelers Details</p>
          <div className="card-card">
            {state?.hotelSellResponse?.guests?.map((guest, index) => (
              <>
                <LabelAndValue
                  label="First Name:"
                  value={guest?.title + " " + guest?.firstName}
                  justifyContent="flex-start"
                />
                <LabelAndValue
                  label="Last Name:"
                  value={guest?.lastName}
                  justifyContent="flex-start"
                />
                <LabelAndValue
                  label="Traveler number:"
                  value={guest?.phone}
                  justifyContent="flex-start"
                />
                <LabelAndValue
                  label="Email:"
                  value={guest?.email}
                  justifyContent="flex-start"
                />
                {state?.hotelSellResponse?.guests?.length - 1 > index && (
                  <Divider sx={{ my: 2 }} />
                )}
              </>
            ))}
          </div>

          <p className="medium-font-size mb-16">Payment Information</p>
          <div className="card-card">
            <LabelAndValue
              label="Method:"
              value={
                state?.hotelSellResponse?.hotelBookings?.[0]?.payment?.method
              }
              justifyContent="flex-start"
            />

            <LabelAndValue
              label="Card Number:"
              value={
                state?.hotelSellResponse?.hotelBookings?.[0]?.payment
                  ?.paymentCard?.paymentCardInfo?.cardNumber
              }
              justifyContent="flex-start"
            />

            <LabelAndValue
              label="Card Number:"
              value={
                state?.hotelSellResponse?.hotelBookings?.[0]?.payment
                  ?.paymentCard?.paymentCardInfo?.holderName
              }
              justifyContent="flex-start"
            />

            <LabelAndValue
              label="Expiry Date:"
              value={
                state?.hotelSellResponse?.hotelBookings?.[0]?.payment?.paymentCard?.paymentCardInfo?.expiryDate?.slice(
                  0,
                  2
                ) +
                "/" +
                state?.hotelSellResponse?.hotelBookings?.[0]?.payment?.paymentCard?.paymentCardInfo?.expiryDate?.slice(
                  2
                )
              }
              justifyContent="flex-start"
            />
          </div>
        </div>
        <aside className="reversation-sideBar-wrapper">
          <div className="reversation-bill">
            <div className="reversation-bill-box">
              <div className="card-card">
                <p className="medium-font-size pb-2">Booking details</p>
                <hr />

                <LabelAndValue
                  label="Check-in "
                  value={
                    state?.hotelSellResponse?.hotelBookings?.[0]?.hotelOffer
                      ?.checkInDate
                  }
                  mb={2}
                />

                <LabelAndValue
                  mt={2}
                  label="Check-out"
                  value={
                    state?.hotelSellResponse?.hotelBookings?.[0]?.hotelOffer
                      ?.checkOutDate
                  }
                />
              </div>
              <div className="card-card">
                <p className="medium-font-size pb-2">Price details</p>
                <hr />

                <LabelAndValue
                  label="Price"
                  value={
                    state?.hotelDetailsAndRoom?.room?.price?.currency +
                    " " +
                    state?.hotelDetailsAndRoom?.room?.price?.total
                  }
                  mb={2}
                />

                <LabelAndValue
                  mt={2}
                  sx={{ "& p": { fontSize: "1rem", fontWeight: "bold" } }}
                  label="Total"
                  value={
                    state?.hotelDetailsAndRoom?.room?.price?.currency +
                    " " +
                    state?.hotelDetailsAndRoom?.room?.price?.total
                  }
                />
              </div>
              <RoomCard
                room={state?.hotelDetailsAndRoom?.room}
                hotelDetails={state?.hotelDetailsAndRoom}
                hasReserveBtn
              />
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default HotelsConfirmation;
