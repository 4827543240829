import { create } from "apisauce";
import { getToken, storeUserInLocalStorage } from "hooks/LocalStorage";
import { customHistory } from "router/custom-router";

// Create the API client
const apiClient = create({
  baseURL:
    process.env.REACT_APP_HOST_URL ||
    "https://bookingapidev.satyamholidays.net/api",
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const logout = () => {
  window.scrollTo(0, 0);

  // customHistory.push("/#auth-required");
  window.location.hash = "#auth-required";
};

// Request interceptor to add the Authorization header
apiClient.axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken()?.authenticationToken;

    if (
      token &&
      !(
        config.url?.includes("authentication/login") ||
        config.url?.includes("authentication/change-password")
      )
    ) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

// Function to refresh the token
const refreshToken = async () => {
  const refreshToken = getToken()?.refreshToken;

  if (!refreshToken) {
    window.location.hash = "auth-required";
    throw new Error("Refresh token is missing");
  }

  try {
    const body = {
      refreshToken,
    };
    const response = await apiClient.post(
      "authentication/refresh-token",
      body,
      {
        headers: { "device-type": "satyam-tours-web" },
      }
    );

    if (response.ok && response.data) {
      const { authenticationToken, refreshToken } = response.data;
      const tokenData = {
        authenticationToken,
        refreshToken,
      };
      storeUserInLocalStorage(tokenData);

      return authenticationToken;
    } else {
      logout();
      throw new Error("Failed to refresh token");
    }
  } catch (error) {
    logout();
    throw error;
  }
};

// Response interceptor to refresh the token on 401 error
apiClient.axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if the original request is a login request
    if (
      originalRequest.url.includes("authentication/login") ||
      originalRequest.url.includes("authentication/change-password")
    ) {
      return Promise.reject(error);
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return apiClient.axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const newToken = await refreshToken();
        processQueue(null, newToken);
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return apiClient.axiosInstance(originalRequest);
      } catch (err) {
        processQueue(err, null);
        return Promise.reject(err);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
