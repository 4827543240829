import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import FlightIcon from "@mui/icons-material/Flight";

import { ReactComponent as Dot } from "assets/images/dot.svg";
import { ReactComponent as Cabinbaggage } from "assets/images/cabinbaggage.svg";
import { ReactComponent as Arrow } from "assets/images/arrow.svg";

import { capitalizeFirstLetter } from "utils/Utils";

import Badge from "../badge";

import { timeDiffCalc, duration, checkISAdminOrEmployee } from "utils/Utils";

import { getUserFromLocalStorage } from "hooks/LocalStorage";
const ItineraryComponent = ({
  itinerary,
  segment,
  dictionaries,
  flight,
  destinationLocationCode,
  originLocationCode,
  index,
  hideClass,
  isScreenShot,
}) => {
  const getAirlineLogo = (carrierCode) => {
    let logo;

    const airlineLogos = dictionaries?.airlineLogos;

    if (airlineLogos && airlineLogos[carrierCode]) {
      logo =
        airlineLogos[carrierCode][0]?.squareImage ||
        airlineLogos[carrierCode][0]?.tailImage;
    }

    return logo;
  };

  return (
    <>
      <div className="result-card-content">
        <p className="result-card-time">
          {moment(segment?.departure?.at).format("ddd D MMM")}
        </p>
        <div>
          <div className="result-card-slice-title">
            <div className="arrow-wrapper">
              <div className="arrow"></div>
              <Dot />
            </div>
            {dictionaries?.locations && (
              <div className="result-card-place">
                <strong>
                  <time>{moment(segment?.departure?.at).format("H:mm")}</time>{" "}
                  <span>
                    {
                      dictionaries?.locations[segment?.departure?.iataCode]
                        ?.city
                    }
                  </span>{" "}
                </strong>
                <div className="gray-color">
                  {dictionaries?.locations[segment.departure.iataCode]?.airport}
                  {", "}
                  {dictionaries?.locations[segment.departure.iataCode]?.country}
                </div>
              </div>
            )}
          </div>
          <div className="result-card-slice-title">
            <div className="arrow-wrapper">
              <div className="arrow"></div>
              <div className="trip-icon">
                <Arrow />
              </div>

              <div className="arrow bottom"></div>
            </div>
            <div className="badges">
              <Badge
                title={duration(segment?.duration, itinerary?.duration)}
                className="teal"
              />

              {getAirlineLogo(segment?.carrierCode) && (
                <Tooltip
                  title={capitalizeFirstLetter(
                    dictionaries?.carriers[segment?.carrierCode]
                  )}
                  arrow
                  placement="top"
                >
                  <Box
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid rgb(232, 237, 241)",
                      mb: 0.5,
                      mr: 0.5,
                      height: "26px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      className="results-card-logo"
                      src={getAirlineLogo(segment?.carrierCode)}
                    />
                  </Box>
                </Tooltip>
              )}
              <Badge
                title={segment?.carrierCode + segment?.number}
                Icon={FlightIcon}
              />

              <Badge
                title={
                  flight?.travelerPricings[0]?.fareDetailsBySegment?.find(
                    (sege) => sege?.segmentId == segment?.id
                  )?.cabin
                }
              />

              <Badge
                title={`${
                  flight?.travelerPricings[0]?.fareDetailsBySegment?.find(
                    (fare) => fare.segmentId === segment?.id
                  )?.includedCheckedBags?.quantity
                    ? flight?.travelerPricings[0]?.fareDetailsBySegment?.find(
                        (fare) => fare.segmentId === segment?.id
                      )?.includedCheckedBags?.quantity
                    : flight?.travelerPricings[0]?.fareDetailsBySegment?.find(
                        (fare) => fare.segmentId === segment?.id
                      )?.includedCheckedBags?.weight
                    ? 1
                    : 0
                }x `}
                Icon={Cabinbaggage}
                className={`blue ${
                  flight?.travelerPricings[0]?.fareDetailsBySegment?.find(
                    (fare) => fare.segmentId === segment?.id
                  )?.includedCheckedBags?.quantity === 0
                    ? "blink"
                    : "unblink"
                }`}
              />
              {(checkISAdminOrEmployee() ||
                getUserFromLocalStorage()?.type === "B_AGENT") &&
                !hideClass && (
                  <Badge
                    title={
                      flight?.travelerPricings[0]?.fareDetailsBySegment?.find(
                        (sege) => sege?.segmentId == segment?.id
                      )?.class
                    }
                    className="teal"
                  />
                )}
            </div>
          </div>
          <div className="result-card-slice-title">
            <div className="arrow-wrapper">
              <div className="arrow bottom"></div>
              <Dot />
            </div>
            {dictionaries?.locations && (
              <div className="result-card-place">
                <strong>
                  <time>{moment(segment?.arrival?.at).format("H:mm")}</time>{" "}
                  <span>
                    {dictionaries?.locations[segment?.arrival?.iataCode]?.city}
                  </span>
                </strong>
                <div className="gray-color">
                  {dictionaries?.locations[segment.arrival.iataCode]?.airport}
                  {", "}
                  {dictionaries?.locations[segment.arrival.iataCode]?.country}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {dictionaries?.locations &&
        itinerary?.segments[itinerary?.segments?.length - 1] !== segment &&
        itinerary?.segments?.length > 1 &&
        segment?.arrival?.iataCode !== destinationLocationCode?.split("/")[0] &&
        segment?.arrival?.iataCode !== originLocationCode?.split("/")[0] && (
          <div className="sector-layover">
            <hr />

            <div className="layover-text">
              Layover in{" "}
              {dictionaries?.locations[segment?.arrival?.iataCode]?.country}{" "}
              {timeDiffCalc(
                new Date(segment?.arrival?.at),
                new Date(itinerary?.segments[index + 1]?.departure?.at)
              )}
            </div>
          </div>
        )}
    </>
  );
};

export default ItineraryComponent;
