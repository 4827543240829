import React, { useEffect, useState, useContext } from "react";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import { useSearchParams, useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";

import FilterCollapse from "components/filterCollapse";
import Banner from "components/banner";
import HotelCard from "./components/HotelCard";

import useApi from "hooks/useApi";

import { getHotelsList } from "api/Hotels";

import { CurrencyContext } from "pages/useContext";

//----------------------------------------------------------------

const HotelsV2 = () => {
  const searchForHotels = useApi(getHotelsList);

  const [currency] = useContext(CurrencyContext);
  let navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [departureDate, setdepartureDate] = useState(
    searchParams.get("startDate") ?? ""
  );

  const [returnDate, setReturnDate] = useState(
    searchParams.get("endDate") ?? ""
  );
  const [popular, setPopular] = useState([
    { value: "Free airport shuttle" },
    { value: "Breakfast included" },
    { value: "Free Cancelation" },
    { value: "Pool" },
  ]);

  const [rating, setRating] = useState([
    { value: "Any" },
    { value: "Wonderful" },
    { value: "Very Good" },
    { value: "Good" },
  ]);

  const fetchHotels = async () => {
    const body = {
      cityCode: searchParams.get("destination"),
      amenities: searchParams.get("amenities") || null,
      ratings: searchParams.get("ratings")
        ? searchParams
            .get("ratings")
            ?.replaceAll(" star", "")
            ?.split(",")
            ?.map(Number)
        : null,
      checkInDate: searchParams.get("startDate"),
      checkOutDate: searchParams.get("endDate"),
      adults: searchParams.get("adults") * 1,
      roomQuantity: searchParams.get("roomQuantity") * 1,
      childAges: searchParams.get("childAges")
        ? searchParams.get("childAges")?.split(",")?.map(Number)
        : null,
    };
    searchForHotels.request({
      body,
    });
  };

  useEffect(() => {
    if (currency?.currency) {
      fetchHotels();
    }
  }, [
    searchParams.get("destination"),
    searchParams.get("startDate"),
    searchParams.get("endDate"),
    searchParams.get("adults"),
    searchParams.get("ratings"),
    searchParams.get("roomStayCandidate"),
    currency?.currency,
    searchParams.get("amenities"),
  ]);

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  const marks = [
    {
      value: 0,
      label: "0$",
    },
    {
      value: 20,
    },
    {
      value: 37,
    },
    {
      value: 100,
      label: "300$",
    },
  ];

  function valuetext(value) {
    return `${value}°C`;
  }

  return (
    <>
      <div className="search-form-wrapper">
        <div className="search-form">
          <Banner
            hideFilter
            activeTabProp="hotelv2"
            departureDate={departureDate}
            setdepartureDate={setdepartureDate}
            returnDate={returnDate}
            setReturnDate={setReturnDate}
          />
        </div>
      </div>
      <div className="search-results-view">
        <div className="search-results-container">
          <div className="search-form-filter">
            <div>
              {/* <Box sx={{ background: "#fff" }}>
                <div style={{ height: "150px", width: "100%" }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: "" }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                  >
                    <AnyReactComponent
                      lat={59.955413}
                      lng={30.337844}
                      text="My Marker"
                    />
                  </GoogleMapReact>
                </div>
                <p className="text-center py-2">View in a map</p>
              </Box> */}
              {/* <FilterCollapse
                headerTitle="Popular Filter"
                collapseChildren={popular}
                checked={false}
              /> */}
              <hr />
              <p>Price per night</p>
              <Box sx={{ width: "235px", textAlign: "center", m: "auto" }}>
                <Slider
                  aria-label="Always visible"
                  defaultValue={80}
                  getAriaValueText={valuetext}
                  step={10}
                  marks={marks}
                  valueLabelDisplay="on"
                />
              </Box>
              <hr />
              <FilterCollapse
                headerTitle="Guest rating"
                collapseChildren={rating}
                checked={false}
              />
            </div>
          </div>
          <div className="search-results-list">
            <Box
              display="flex"
              justifyContent="space-between"
              flexWrap="wrap"
              mb={2}
            >
              {!searchForHotels?.loading && (
                <div>
                  <h1 className="primary-color bolder sub-main-title ">
                    {searchParams.get("destination")}:{" "}
                    {searchForHotels?.data?.data?.length} properties found
                  </h1>
                  <p className="mb-3">
                    See how we pick our recommended properties
                  </p>
                </div>
              )}
            </Box>

            {searchForHotels?.loading ? (
              [...Array(7)].map((_, index) => (
                <Box display="flex" bgcolor="#fff" key={index} mb={3} p={2}>
                  <Skeleton
                    variant="rectangular"
                    width={"50%"}
                    height={150}
                    sx={{
                      bgcolor: "#F1F3F6",
                      borderRadius: "4px",
                    }}
                    animation="wave"
                  />
                  <Box width="100%" ml={2}>
                    <Skeleton
                      variant="text"
                      width={"60%"}
                      height={30}
                      sx={{
                        marginBottom: 1,
                        bgcolor: "#F1F3F6",
                        borderRadius: "4px",
                      }}
                      animation="wave"
                    />
                    <Skeleton
                      variant="text"
                      width={"60%"}
                      height={30}
                      sx={{
                        marginBottom: 1,
                        bgcolor: "#F1F3F6",
                        borderRadius: "4px",
                      }}
                      animation="wave"
                    />
                  </Box>
                </Box>
              ))
            ) : searchForHotels?.error ||
              searchForHotels?.data?.availableHotels?.length === 0 ? (
              <div className="empty-data-msg">
                <h1 className="medium-font-size title-header">
                  No available Hotels found !
                </h1>
              </div>
            ) : (
              searchForHotels?.data?.availableHotels?.map((hotel, index) => (
                <HotelCard
                  key={index}
                  hotel={hotel}
                  destination={searchParams.get("destination")}
                  startDate={searchParams.get("startDate")}
                  endDate={searchParams.get("endDate")}
                  roomStayCandidate={searchParams.get("roomQuantity") * 1}
                  guestCount={searchParams.get("adults") * 1}
                />
              ))
            )}
          </div>
          <div className="search-sideBar-wrapper"></div>
        </div>
      </div>
    </>
  );
};

export default HotelsV2;
