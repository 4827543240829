import client from "./client";

const endpoint = "hotels/";

const getHotels = ({ body }) =>
  client.post(endpoint + "multi-single-availability-stateless", body);

const getHotelsList = ({ body }) => client.post(endpoint + "list", body);

const getHotleRoomsDetails = ({ body }) =>
  client.post(endpoint + "multi-single-availability-stateful", body);

const enhancedPricing = ({ body }) =>
  client.post(endpoint + "enhanced-pricing", body);

const multiElements = ({ body }) =>
  client.post(endpoint + "multi-elements", body);

const hotelSell = ({ body }) => client.post(endpoint + "hotel-sell", body);

const hotelDescriptiveInfo = ({ hotelCode }) =>
  client.get(endpoint + "hotel-descriptive-info/" + hotelCode);

// HOTEL V2
const getOffer = ({ offerId }) => client.get(endpoint + "offers/" + offerId);

const hotelPayment = ({ body }) =>
  client.post(endpoint + "hotel-booking/", body);

export {
  getHotels,
  getHotleRoomsDetails,
  getHotelsList,
  enhancedPricing,
  multiElements,
  hotelSell,
  hotelDescriptiveInfo,
  getOffer,
  hotelPayment,
};
