import { useNavigate } from "react-router-dom";
import { fDate } from "utils/time-format";

import Box from "@mui/material/Box";
import LandscapeIcon from "@mui/icons-material/Landscape";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import HotelIcon from "@mui/icons-material/Hotel";
import Radio from "@mui/material/Radio";

import Badge from "components/badge";
import Button from "components/button";
import ImgSlider from "components/hotels/img-slider";

const HotelCard = ({
  handelShowRoomModal,
  room,
  session,
  hotelDetails,
  hasReserveBtn,
}) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    const hotelDetailsAndRoom = {
      ...hotelDetails,
      session,
      room,
    };
    navigate("/hotelv2-payment", {
      state: { hotelDetailsAndRoom },
    });
  };

  console.log("hotelDetails", room);

  return (
    <Box borderRadius={2} bgcolor="#fff" overflow="hidden">
      <Box
        onClick={() => handelShowRoomModal(true)}
        sx={{ cursor: "pointer", "& img": { height: "200px" } }}
      >
        <ImgSlider />
      </Box>
      <Box p={"0.75rem"} border="1px solid #eee">
        <p className="bolder">{room?.room?.description?.text}</p>

        {/* <Box display="flex" mb={1} alignItems="center">
          <SquareFootIcon />
          <p className="mx-2">409 sq ft</p>
        </Box> */}
        <Box display="flex" mb={1} alignItems="center">
          <p className="mr-2">
            Category: {room?.roomInformation?.typeEstimated?.category}
          </p>
        </Box>
        <Box display="flex" mb={1} alignItems="center">
          <HotelIcon />
          <p className="mx-2">
            {room?.roomInformation?.typeEstimated?.beds} beds
          </p>
        </Box>

        <Box display="flex" mb={1} alignItems="center">
          <Box className="mr-2">
            <strong> Cancellations Policies:</strong>
            {room?.policies?.cancellations?.map((item) => (
              <>
                <p>
                  <strong>Deadline</strong>: {fDate(item?.deadline)}
                </p>
                {item?.description?.text}
              </>
            ))}
          </Box>
        </Box>
        {/* <a href="£" className="app-link">
          More Details{"  > "}
        </a> */}
      </Box>
      {/* 
      <Box p={"0.75rem"} border="1px solid #eee">
        <p className="bolder">Options</p>

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Radio size="small" />
            <p>Non-Refundable</p>
          </Box>

          <p>$230</p>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Radio size="small" />
            <p>Fully refundable before Jan 13</p>
          </Box>

          <p>$230</p>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Radio size="small" />
            <p>Fully refundable before Jan 17</p>
          </Box>

          <p>$230</p>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Radio size="small" />
            <p>Fully refundable before Jan 17 + Half board</p>
          </Box>

          <p>$230</p>
        </Box>
        <a href="£" className="app-link">
          More Details{"  > "}
        </a>
      </Box> */}
      {!hasReserveBtn && (
        <Box pt={2} p={"0.75rem"} border="1px solid #eee">
          {/* <Badge title="12% off" className="m-0 teal" /> */}
          {/* <p className="sub-main-title bolder my-1">
            {" "}
            {room?.room?.currency} {room?.room?.priceBeforeTax}
          </p>
          <p>
            {room?.room?.currency} {room?.room?.tax} taxes
          </p> */}
          <p className="mt-2">
            {room?.price?.currency} {room?.price?.total} total
          </p>

          <p>includes taxes & fees</p>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {/* <a href="£" className="app-link">
              Price Details{"  > "}
            </a> */}
            <Button className="primary-color mt-2" onClick={handleNavigate}>
              Reserve{" "}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default HotelCard;
