import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import ClickAwayListener from "@mui/core/ClickAwayListener";
import Button from "@mui/material/Button";
import moment from "moment";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ImUser } from "react-icons/im";
import HotelIcon from "@mui/icons-material/Hotel";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import { capitalizeFirstLetter } from "utils/Utils";
import CloseIcon from "@mui/icons-material/Close";
import LinearProgress from "@mui/material/LinearProgress";
import { IconButton, Typography } from "@mui/material";

import "./style.css";

import BannerInput from "../bannerInput";
import ContainerSearch from "../containerSearch";
import DatePicker from "../datePicker";
import DropDown from "../dropDown";
import Modal from "../swipeDrawer";
import MainButton from "../button";
import AppListItem from "../listItem";

import { ReactComponent as Return } from "assets/images/return.svg";
import { ReactComponent as OneWay } from "assets/images/oneWay.svg";
import { ReactComponent as MultiCity } from "assets/images/multiCity.svg";
import { ReactComponent as Nomed } from "assets/images/nomed.svg";
import { ReactComponent as Children } from "assets/images/children.svg";
import { ReactComponent as Infants } from "assets/images/infants.svg";
import { ReactComponent as Airplate } from "assets/images/airplate.svg";

import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";

import useApi from "hooks/useApi";
import { getAirportByIataCode } from "api/Airport";
import { checkISAdminOrEmployee } from "utils/Utils";

import AirportMobileList from "./airport-mobile-list";

import HotelsTab from "./search-tabs/hotels-tab";
import CarsTab from "./tabs/cars-tab";
import HotelV2Tab from "./tabs/hotelv2-tab";

import BannerInputSection from "./banner-input";

//----------------------------------------------------------------

const Banner = ({
  departureDate,
  setdepartureDate,
  returnDate,
  setReturnDate,
  handleExplore,
  hideFilter,
  airlinesCodes,
  airlineCarrier,
  includedCarrierCodes,
  onChangeCarrier,
  activeTabProp = "flights",
  allFilters,
  setAllFilters,
  isSearchable,
  setRedirectLink,
  loading,
}) => {
  const { search } = useLocation();
  let searchParams = new URLSearchParams(search);

  const [show, setShow] = useState(false);
  const [flightId, setFlightID] = useState(0);
  const [activeTab, setActiveTab] = useState(activeTabProp);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [activeList, setActiveList] = useState(true);
  const [refundableFare, setRefundableFare] = useState(false);

  const [tabs, setTabs] = useState(["Flights", "Hotels", "HotelV2"]);
  const [checkedBag, setCheckedBag] = useState(false);

  const [originLocationCode, setOriginLocationCode] = useState({
    cityCode: searchParams.get("origin") ? searchParams.get("origin") : "",
    cityName: searchParams.get("origin")?.split("/")[1] ?? "",
  });
  const [destinationLocationCode, setDestinationLocationCode] = useState({
    cityCode: searchParams.get("destination") ?? "",
    cityName: searchParams.get("destination")?.split("/")[1] ?? "",
  });

  const [classKey, setClassKey] = useState("ECONOMY");
  const [keyword, setKeyword] = useState("");
  const [keyword2, setKeyword2] = useState("");
  let navigate = useNavigate();

  const getAirportByIataCodeApi = useApi(getAirportByIataCode);
  const getAirportByIataCodeApi2 = useApi(getAirportByIataCode);

  useEffect(() => {
    if (keyword.length >= 2) {
      getAirportByIataCodeApi.request({ keyword });
    }
    if (keyword2.length >= 2) {
      getAirportByIataCodeApi2.request({ keyword: keyword2 });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
  }, [keyword, keyword2]);

  const toggleDrawer = (open, type, id, fieldType) => (event) => {
    if (type) {
      setActiveList(type);
    }
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (fieldType === "departure") {
      setShow("dateFrom");
    } else {
      setShow("dateTo");
    }

    if (id) {
      setFlightID(id);
    }
    setOpenDrawer(open);
  };

  const [returnOption, setReturnOption] = useState({
    currentOption: searchParams.get("flight") ?? "Return",
    options: [
      { id: 1, value: "Return", key: "Return" },
      { id: 2, value: "One-way", key: "One-way" },
      { id: 3, value: "Multi-city", key: "Multi-city" },
    ],
  });

  const [classOption, setClassOption] = useState({
    currentOption: searchParams.get("cabinType") ?? "Economy",
    options: [
      { id: 1, value: "Economy", key: "ECONOMY", checked: false },
      {
        id: 2,
        value: "Premium Economy",
        key: "PREMIUM_ECONOMY",
        checked: false,
      },
      { id: 3, value: "Business", key: "BUSINESS", checked: false },
      { id: 4, value: "First Class", key: "FIRST", checked: false },
    ],
  });

  const [rooms, setRooms] = useState([
    { value: "Adult", key: "adult", num: 1 },
    { value: "Children", key: "children", num: 0, childAge: [] },
    { value: "How many Rooms", key: "rooms", num: 1 },
  ]);

  const [infoSource, setInfoSource] = useState({
    selected: searchParams.get("infoSource") ?? "Distribution",
    options: [
      {
        value: "Distribution",
        label: "Distribution",
      },
      {
        value: "Leisure",
        label: "Leisure",
      },
      {
        value: "MultiSource",
        label: "MultiSource",
      },
    ],
  });

  const [stops, setStops] = useState({
    currentOption: { id: 2, value: "Up to 1 stop", key: 1 },
    options: [
      { id: 1, value: "Non Stop(direct)", key: 0, checked: false },
      { id: 2, value: "Up to 1 stop", key: 1, checked: false },
      { id: 3, value: "Up to 2 stop", key: 2, checked: false },
    ],
  });

  const getSpecificFilter = (label) => {
    const filter = allFilters?.find((filter) => filter.label === label);
    return filter;
  };

  const changeStops = (item) => {
    setStops({ ...stops, currentOption: item });

    handleUpdateFilters(getSpecificFilter("Stops"), item);
  };

  const changeType = (item, type) => {
    setReturnOption({ ...returnOption, currentOption: item.value });
    if (item.value === "Multi-city") {
      handleAddDestination();
    } else {
      clearFlightDetails();
    }
    // setReturnDate("");
    if (hideFilter) {
      if (item.value === "Return") {
        if (type === "mobile") {
          toggleDrawer(true, "calendar");
        } else {
          setTimeout(() => setShow("dateTo"), 500);
          window.scrollTo(0, 0);
        }
      }
    }
  };

  const changeClass = (item) => {
    setClassOption({ ...classOption, currentOption: item.value });
    setClassKey(item.key);
    handleUpdateFilters(getSpecificFilter("Cabin Type"), item);
  };

  const chooseType = (type) => {
    const obj = {
      Return: <Return />,
      "One-way": <OneWay />,
      "Multi-city": <MultiCity />,
    };
    return obj[type] ?? <Nomed />;
  };

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const [passerngersType, setPassengerType] = useState([
    {
      value: "Senior",
      subTitle: "60+",
      num: +searchParams.get("Senior") || 0,
    },
    {
      value: "Adults",
      subTitle: "Over 11",
      num: +searchParams.get("Adults") || 1,
    },
    {
      value: "Children",
      subTitle: "2-11",
      num: +searchParams.get("Children") || 0,
    },
    {
      value: "Infants",
      subTitle: "Under 2",
      num: +searchParams.get("Infants") || 0,
    },
  ]);

  const [bagsType, setBags] = useState([{ value: "Checked baggage" }]);

  const onClickMinus = (index, pass) => {
    if (pass) {
      if (passerngersType[index].num > 0) {
        const newItems = [...passerngersType];
        passerngersType[index].num = passerngersType[index].num - 1;
        setPassengerType(newItems);
      }
    }
  };

  const onClickPlus = (value, pass) => {
    let currentPassenger = passerngersType?.find(
      (passer) => passer.value == value
    )?.num;

    if (pass) {
      if (
        (value === "Senior" && currentPassenger >= 9) ||
        (value === "Adults" && currentPassenger >= 9) ||
        (value === "Infants" &&
          currentPassenger >=
            passerngersType?.find((passer) => passer.value === "Adults")?.num)
      ) {
        return;
      } else {
        setPassengerType((prev) => {
          const newItems = [...prev];
          newItems.find((i) => i.value === value).num = currentPassenger + 1;
          return newItems;
        });
      }
    }
  };

  const getNumOFRoomDetails = (type) => {
    const num = rooms.find((room) => room.key === type);

    if (type === "children") {
      return num?.childAge;
    }
    return num?.num;
  };

  const redirectSearch = (e) => {
    let link;

    if (activeTab === "hotelv2") {
      const getHotelDetails = multiCity[0];
      link = {
        pathname: `/hotelv2`,
        search: `destination=${
          destinationLocationCode.cityCode
        }&startDate=${moment(getHotelDetails.originDate).format(
          "Y-MM-DD"
        )}&endDate=${moment(getHotelDetails.destinationDate).format(
          "Y-MM-DD"
        )}&amenities=${amenities.selected}&ratings=${
          rating.selected
        }&childAges=${getNumOFRoomDetails(
          "children"
        )}&adults=${getNumOFRoomDetails(
          "adult"
        )}&roomQuantity=${getNumOFRoomDetails("rooms")}`,
      };
    } else if (activeTab === "hotels") {
      const getHotelDetails = multiCity[0];
      link = {
        pathname: `/hotels`,
        search: `destination=${
          destinationLocationCode.cityCode
        }&startDate=${moment(getHotelDetails.originDate).format(
          "Y-MM-DD"
        )}&endDate=${moment(getHotelDetails.destinationDate).format(
          "Y-MM-DD"
        )}&guestCount=${getNumOFRoomDetails(
          "adult"
        )}&children=${getNumOFRoomDetails(
          "children"
        )}&roomStayCandidate=${getNumOFRoomDetails("rooms")}&infoSource=${
          infoSource.selected
        }`,
      };
    } else {
      const origin = multiCity
        .map((flight) => flight.origin.cityCode + "-" + flight.origin.cityName)
        .join("~");

      const destination = multiCity
        .map(
          (flight) =>
            flight.destination.cityCode + "-" + flight.destination.cityName
        )
        .join("~");

      const originsDates = multiCity
        .map((i) => moment(i.originDate).format("Y-MM-DD"))
        .join("~");

      const destinationDates =
        returnOption.currentOption === "Return"
          ? multiCity
              .map((i) => moment(i.destinationDate).format("Y-MM-DD"))
              .join("~")
          : null;

      if (hideFilter) {
        searchParams.set("origin", `${origin}`);
        searchParams.set("destination", `${destination}`);
        searchParams.set("Senior", passerngersType[0].num);
        searchParams.set("Adults", passerngersType[1].num);
        if (passerngersType[1].num > 0) {
          searchParams.set("Children", passerngersType[2].num);
        } else {
          searchParams.delete("Children");
        }
        if (passerngersType[1].num > 0) {
          searchParams.set("Infants", passerngersType[3].num);
        } else {
          searchParams.delete("Infants");
        }

        searchParams.set("flight", returnOption.currentOption);

        searchParams.set("departure-date", originsDates);
        if (destinationDates) {
          searchParams.set("return-date", destinationDates);
        }
        navigate({
          pathname: `/search`,
          search: searchParams.toString(),
        });

        const getOriginsDates = originsDates.split("~")[0];
        let getDestinationDates =
          returnOption.currentOption === "Return"
            ? destinationDates.split("~")[0]
            : returnOption.currentOption === "One-way"
            ? originsDates.split("~")[0]
            : originsDates.split("~")[originsDates.split("~").length - 1];

        handleExplore(
          getOriginsDates,
          getDestinationDates,
          originLocationCode,
          destinationLocationCode
        );
      } else {
        e.preventDefault();

        link = {
          pathname: `/search`,
          search: `origin=${origin}&destination=${destination}${
            getFilterBySelectedKey("cabinType")
              ? `&cabinType=${getFilterBySelectedKey("cabinType")}`
              : ""
          }&Senior=${passerngersType[0].num}&Adults=${passerngersType[1].num}${
            (passerngersType[2].num > 0 ? passerngersType[2].num : "") &&
            `&Children=${passerngersType[2].num}`
          }${
            (passerngersType[3].num > 0 ? passerngersType[3].num : "") &&
            `&Infants=${passerngersType[3].num}`
          }&stops=${getFilterBySelectedKey("stops")}${
            originsDates && `&departure-date=${originsDates}`
          }&refundableFare=${getFilterBySelectedKey("refundableFare")}${
            returnOption.currentOption === "Return"
              ? `&return-date=${destinationDates}`
              : ""
          }&flight=${
            returnOption.currentOption
          }&checkedbaggage=${getFilterBySelectedKey(
            "checkedbaggage"
          )}&satyamSpecial=${getFilterBySelectedKey("satyamSpecial")}
        `,
        };
      }
    }

    navigate(link);
    window.scrollTo(0, 0);

    setRedirectLink(link);
  };

  const getFilterBySelectedKey = (filterName) => {
    if (filterName === "checkedbaggage") {
      const getFilter = allFilters?.find((filter) => filter.multiple);
      const getKey = getFilter.options.find(
        (option) => option.value === "Checked baggage"
      );
      return getKey.key;
    } else if (filterName === "satyamSpecial") {
      const getFilter = allFilters?.find((filter) => filter.multiple);
      const getKey = getFilter.options.find(
        (option) => option.value === "Satyam Special"
      );
      return getKey.key;
    } else if (filterName === "refundableFare") {
      const getFilter = allFilters?.find((filter) => filter.key === filterName);
      return getFilter.options[0].key;
    } else {
      const getFilter = allFilters?.find((filter) => filter.key === filterName);

      const getKey = getFilter?.options?.find((option) => option.checked);

      if (getKey) return getKey.key;
      return getFilter?.defaultOption;
    }
  };

  const handleUpdateFilters = (currentFilter, selectedOption) => {
    // Change the filter state based on the current filter
    const oldState = [...allFilters];
    const findOptionsOFFilter = allFilters?.find(
      (filter) => filter.key === currentFilter.key
    ).options;
    let checkParam =
      typeof selectedOption.key === "boolean"
        ? !selectedOption.key
        : selectedOption.key;

    findOptionsOFFilter.forEach((item) => {
      /// Update the radio checkbox state
      if (item.value === selectedOption.value) {
        if (!currentFilter.key) {
          if (item.value === "Checked baggage") {
            searchParams.set("checkedbaggage", !item.checked);
          } else if (item.value === "Satyam Special") {
            checkParam = item.checked ? "PUBLISHED" : "NEGOTIATED";
            item.key = item.checked ? "PUBLISHED" : "NEGOTIATED";
            searchParams.set(
              "satyamSpecial",
              item.checked ? "PUBLISHED" : "NEGOTIATED"
            );
          }
        }
        item.checked = !item.checked;
        if (typeof selectedOption.key === "boolean") {
          item.key = checkParam;
        }
      } else if (currentFilter.key) {
        item.checked = false;
      }
    });

    //Remove the return value from the url in case the chosen is one-way
    if (selectedOption.key === "One-way") {
      // searchParams.delete("return-date");
      setReturnDate("");
    }

    /// Handle the search params
    if (currentFilter.key) {
      const checkIfExists = searchParams.get(currentFilter.key);
      if (checkIfExists === checkParam) {
        searchParams.set(currentFilter.key, currentFilter.defaultOption);
      } else {
        searchParams.set(currentFilter.key, checkParam);
      }
    }
    if (hideFilter) {
      // Update the search url with new params
      navigate({
        pathname: `/search`,
        search: searchParams.toString(),
      });
    }
    // Update the filter state
    setAllFilters(oldState);
  };

  const list = () => (
    <Box
      sx={{
        width: "auto",
        height:
          activeList === "departure" || activeList === "return-flight"
            ? "100vh"
            : "auto",
      }}
      display="flex"
      flexDirection="column"
      role="presentation"
    >
      {activeList === "pass" ? (
        <List
          sx={{ px: 2, mb: 10, mt: 2, overflowY: "scroll", height: "85vh" }}
        >
          <div className="mb-24">
            <h2 className="medium-font-size">Passengers</h2>
            {passerngersType.map((item, index) => (
              <AppListItem
                key={index}
                index={index}
                title={item.value}
                subTitle={item.subTitle}
                Icon={index === 0 ? ImUser : index === 1 ? Children : Infants}
                num={item.num}
                onClickMinus={() => onClickMinus(index, "pass")}
                onClickPlus={() => onClickPlus(item.value, "pass")}
                pass
              />
            ))}
          </div>

          {allFilters?.map((filter, index) =>
            !filter?.permission ? (
              <>
                <h2 key={index} className="medium-font-size">
                  {filter.label}
                </h2>
                <div className="mb-24">
                  {filter.options.map((item, index) => (
                    <ListItem key={index} sx={{ px: 0, py: 0 }}>
                      <ListItemText
                        primary={item.value}
                        className="list-item-text"
                      />
                      <Radio
                        onClick={() => {
                          handleUpdateFilters(filter, item);
                        }}
                        checked={item.checked}
                        color="default"
                      />
                    </ListItem>
                  ))}
                  {allFilters?.length !== index + 1 && <hr />}
                </div>
              </>
            ) : (
              checkISAdminOrEmployee() && (
                <>
                  <h2 key={index} className="medium-font-size">
                    {filter.label}
                  </h2>
                  <div className="mb-24">
                    {filter.options.map((item, index) => (
                      <ListItem key={index} sx={{ px: 0, py: 0 }}>
                        <ListItemText
                          primary={item.value}
                          className="list-item-text"
                        />
                        <Radio
                          onClick={() => {
                            handleUpdateFilters(filter, item);
                          }}
                          checked={item.checked}
                          color="default"
                        />
                      </ListItem>
                    ))}
                  </div>
                  <hr />
                </>
              )
            )
          )}

          {hideFilter && (
            <Box my={1}>
              <h2 className="medium-font-size">Airline Carriers</h2>
              {airlinesCodes?.map((item, index) => (
                <ListItem
                  onClick={() => onChangeCarrier(item)}
                  key={index}
                  sx={{ px: 0, py: 0 }}
                >
                  <img
                    className="results-card-logo logos-collapse"
                    src={airlineCarrier[item]?.carrierLogo[0]?.squareImage}
                  />
                  <ListItemText
                    primary={capitalizeFirstLetter(
                      airlineCarrier[item]?.carrierName
                    )}
                    className="list-item-text"
                  />
                  <Radio
                    checked={includedCarrierCodes?.includes(item)}
                    color="default"
                  />
                </ListItem>
              ))}
            </Box>
          )}
        </List>
      ) : activeList === "return" ? (
        <List>
          {returnOption.options.map((item, index) => (
            <ListItem
              onClick={() => {
                changeType(item, "mobile");
              }}
              key={index}
            >
              <ListItemIcon>{chooseType(item.value)}</ListItemIcon>
              <ListItemText primary={item.value} />
              <Radio
                checked={item.value === returnOption.currentOption}
                color="default"
              />
            </ListItem>
          ))}
        </List>
      ) : activeList === "calendar" ? (
        <DatePicker
          setReturnDate={setReturnDate}
          isMobile={isMobile}
          toggleDrawer={toggleDrawer(false)}
          returnDate={returnDate}
          departureDate={
            multiCity?.find((flight) => flight.id === flightId)?.originDate
          }
          flightType={returnOption.currentOption}
          disabled={returnOption.currentOption !== "Return"}
          setdepartureDate={(date) => {
            handelUpdateFlightsData({
              id: flightId,
              key: "originDate",
              value: date,
            });
          }}
          dateDifference={moment(
            multiCity?.find((flight) => flight.id === flightId)?.originDate
          ).diff(moment(multiCity[flightId - 2]?.originDate), "days")}
          onSelect={(dates) => {
            handelUpdateFlightsData({
              id: flightId,
              key: "originDate",
              value: dates.startDate,
            });
            handelUpdateFlightsData({
              id: flightId,
              key: "destinationDate",
              value: dates.endDate,
            });
          }}
          show={show}
          setShow={setShow}
        />
      ) : activeList === "departure" ? (
        <AirportMobileList
          placeholder="From"
          onItemClick={(item) => {
            setKeyword(item?.cityName);
            setOriginLocationCode(item);
            setOpenDrawer(false);

            handelUpdateFlightsData({
              id: flightId,
              key: "origin",
              value: item,
            });
          }}
          keyword={keyword}
          airports={getAirportByIataCodeApi}
          setKeyword={setKeyword}
        />
      ) : (
        <AirportMobileList
          placeholder="To"
          onItemClick={(item) => {
            setKeyword2(item?.cityName);
            setDestinationLocationCode(item);
            handelUpdateFlightsData({
              id: flightId,
              key: "destination",
              value: item,
            });
            setOpenDrawer(false);
          }}
          keyword={keyword2}
          airports={getAirportByIataCodeApi2}
          setKeyword={setKeyword2}
        />
      )}
      {activeList !== "calendar" && (
        <>
          <Divider />
          <div className="btns-wrapper">
            <MainButton className="bigBtn" onClick={toggleDrawer(false)}>
              Cancel
            </MainButton>
            <MainButton
              onClick={() => {
                toggleDrawer(false)();
                redirectSearch();
              }}
              className="bigBtn primary-color"
            >
              Done
            </MainButton>
          </div>
        </>
      )}
    </Box>
  );

  const getEnv = true;

  /// Multi City
  const initialData = [
    {
      id: 1,
      origin: "",
      destination: "",
      originDate: "",
    },
  ];

  const [multiCity, setMultiCity] = useState(initialData);

  /// Update multi City

  const handelUpdateFlightsDataFromURL = () => {
    if (searchParams.get("startDate")) {
      const hotelObject = {
        id: 1,
        destination: searchParams.get("destination"),
        originDate: searchParams.get("startDate"),
        destinationDate: searchParams.get("endDate"),
      };
      setMultiCity([hotelObject]);
    }
    if (searchParams.get("origin")) {
      const origins = searchParams.get("origin").split("~");
      const destination = searchParams.get("destination").split("~");
      const originDate = searchParams.get("departure-date").split("~");

      let destinationDate;
      if (searchParams.get("return-date")) {
        destinationDate = searchParams.get("return-date").split("~");
      }

      const dataFromSearchURL = [];

      origins.map((item, index) => {
        const object = {
          id: index + 1,
          origin: {
            cityCode: item.split("-")[0],
            cityName: item.split("-")[1],
          },
          destination: {
            cityCode: destination[index].split("-")[0],
            cityName: destination[index].split("-")[1],
          },
          originDate: originDate[index],
          ...(searchParams.get("return-date") && {
            destinationDate: destinationDate[index],
          }),
        };
        dataFromSearchURL.push(object);
      });

      setMultiCity(dataFromSearchURL);
    }
  };

  useEffect(() => {
    handelUpdateFlightsDataFromURL();
  }, [searchParams.get("departure-date")]);

  const handleAddDestination = () => {
    if (multiCity.length === 1) {
      handelUpdateFlightsData({
        id: 1,
        key: "originDate",
        value: moment(new Date()).add(7, "days").format("YYYY-MM-DD"),
      });
    }
    if (multiCity.length < 4) {
      const flight = {
        id: multiCity.length + 1,
        origin: "",
        destination: "",
        originDate: moment(multiCity[multiCity.length - 1]?.originDate)
          .add(3, "days")
          .format("YYYY-MM-DD"),
      };
      setMultiCity([...multiCity, flight]);
    }
  };

  const handleRemoveDestination = (id) => {
    if (multiCity.length > 1) {
      const flights = multiCity
        .filter((i) => i.id !== id)
        .map((item, index) => ({ ...item, id: index + 1 }));
      setMultiCity(flights);
    }
  };

  const handelUpdateFlightsData = ({ id, key, value }) => {
    const flightDetails = [...multiCity];

    flightDetails.map((flight, index) => {
      if (flight.id === id) {
        flight[key] = value;
        return flight;
      }
      if (
        flight.id > id &&
        key === "originDate" &&
        returnOption.currentOption === "Multi-city"
      ) {
        flight[key] = moment(flightDetails[index - 1]?.originDate)
          .add(3, "days")
          .format("YYYY-MM-DD");
        return flight;
      }
      return flight;
    });

    setMultiCity(flightDetails);
  };

  const clearFlightDetails = () => {
    const filteredFlightsDetails = multiCity.shift();
    setMultiCity([filteredFlightsDetails]);
  };

  const isBtnValid = Boolean(
    activeTab === "flights"
      ? multiCity.find((i) => i.origin === "") ||
          multiCity.find((i) => i.destination === "") ||
          multiCity.find((i) => i.originDate === "") ||
          (returnOption.currentOption !== "One-way"
            ? multiCity.find((i) => i.destinationDate === "")
            : false)
      : !destinationLocationCode?.cityCode ||
          multiCity.find((i) => i.originDate === "") ||
          multiCity.find((i) => i.destinationDate === "")
  );

  const [amenities, setAmenities] = useState({
    selected: searchParams.get("amenities") || "",
    options: [
      { value: "Pool", label: "Pool" },
      { value: "Parking", label: "Parking" },
      { value: "Wifi", label: "Wifi" },
    ],
  });

  const [rating, setRating] = useState({
    selected: searchParams.get("ratings") || "",
    options: [
      { value: "1 star", label: "1 star" },
      { value: "2 star", label: "2 star" },
      { value: "3 star", label: "3 star" },
      { value: "4 star", label: "4 star" },
      { value: "5 star", label: "5 star" },
    ],
  });

  return (
    <>
      <Box mt={2}>
        <Box
          className="card card-above"
          display="flex"
          alignItems="center"
          flexDirection="row"
          justifyContent="center"
          flexWrap="wrap"
          gap={2}
        >
          {tabs.map((tab, index) => (
            <Box
              key={index}
              className={`tab ${
                (tab.toLocaleLowerCase() === "hotels" &&
                  !checkISAdminOrEmployee()) ||
                (tab.toLocaleLowerCase() === "cars" && !getEnv)
                  ? "disabled"
                  : activeTab === tab.toLocaleLowerCase()
                  ? "active"
                  : "inactive"
              }`}
              onClick={() => {
                setActiveTab(tab.toLocaleLowerCase());
                setReturnOption({ ...returnOption, currentOption: "Return" });
                clearFlightDetails();
              }}
            >
              {tab.toLocaleLowerCase() === "flights" ? (
                <Airplate />
              ) : tab.toLocaleLowerCase() === "hotels" ||
                tab.toLocaleLowerCase() === "hotelv2" ? (
                <HotelIcon />
              ) : tab.toLocaleLowerCase() === "cars" ? (
                <DepartureBoardIcon />
              ) : (
                <DepartureBoardIcon />
              )}

              {tab}
            </Box>
          ))}
        </Box>
        <Box
          className={`${
            returnOption.currentOption !== "Return" ? " hide-returnField" : " "
          } card ${activeTab}
          `}
        >
          {activeTab === "cars" ? (
            <CarsTab isMobile={isMobile} />
          ) : (
            <>
              <div className="d-flex flex-wrap">
                {activeTab === "flights" && (
                  <>
                    <DropDown
                      activeOption={returnOption.currentOption}
                      options={returnOption.options}
                      onClick={changeType}
                    />

                    <div className="mobile-view">
                      <Button onClick={toggleDrawer(true, "return")}>
                        {chooseType(returnOption.currentOption)}
                        {returnOption.currentOption}
                      </Button>
                      <Button onClick={toggleDrawer(true, "pass")}>
                        Filters
                      </Button>
                    </div>
                  </>
                )}
                {!hideFilter && activeTab === "flights" && (
                  <>
                    <DropDown
                      activeOption={classOption.currentOption}
                      options={classOption.options}
                      onClick={changeClass}
                    />

                    <DropDown
                      activeOption={stops.currentOption.value}
                      options={stops.options}
                      onClick={changeStops}
                    />
                  </>
                )}
                {activeTab === "flights" && (
                  <>
                    <DropDown
                      options={passerngersType}
                      options2={bagsType}
                      checkedBag={checkedBag}
                      onChangeRadio={() => {
                        setCheckedBag(!checkedBag);
                        handleUpdateFilters(
                          getSpecificFilter("Baggage"),
                          getSpecificFilter("Baggage").options[0]
                        );
                      }}
                      activeTab={activeTab}
                      onClickMinus={onClickMinus}
                      onClickPlus={onClickPlus}
                      hideFilter={hideFilter}
                      setActiveTab={setActiveTab}
                      className="checked-items"
                      redirectSearch={hideFilter && redirectSearch}
                    />
                  </>
                )}
                {activeTab === "hotels" && (
                  <HotelsTab
                    rooms={rooms}
                    setRooms={setRooms}
                    infoSource={infoSource}
                    setInfoSource={setInfoSource}
                    isSearchable={isSearchable}
                  />
                )}

                {activeTab === "hotelv2" && (
                  <HotelV2Tab
                    rooms={rooms}
                    setRooms={setRooms}
                    isSearchable={isSearchable}
                    amenities={amenities}
                    setAmenities={setAmenities}
                    rating={rating}
                    setRating={setRating}
                  />
                )}

                {!hideFilter && activeTab === "flights" && (
                  <Box
                    mt={0.5}
                    sx={{
                      display: { xs: "none", sm: "inline-block" },
                    }}
                    onClick={() => {
                      setRefundableFare(!refundableFare);
                      handleUpdateFilters(
                        getSpecificFilter("Refundable Fare"),
                        getSpecificFilter("Refundable Fare").options[0]
                      );
                    }}
                  >
                    <Radio
                      checked={refundableFare}
                      size="small"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 14,
                        },
                      }}
                      color="default"
                    />{" "}
                    Refundable Fare
                  </Box>
                )}
              </div>

              <ClickAwayListener
                onClickAway={() => {
                  setShow(null);
                }}
              >
                <Box>
                  {multiCity?.map((flight, index) => (
                    <div className="d-flex banner-content">
                      {returnOption.currentOption === "Multi-city" && (
                        <Typography
                          mb={1}
                          mt={1}
                          sx={{ display: { xs: "block", md: "none" } }}
                          fontWeight="bold"
                        >
                          Trip {index + 1}
                        </Typography>
                      )}
                      <div className="block-group">
                        {activeTab === "flights" ? (
                          <>
                            <Box
                              position="relative"
                              className="block-group-item"
                            >
                              <BannerInputSection
                                placeholder="From"
                                toggleDrawer={toggleDrawer(
                                  true,
                                  "departure",
                                  flight.id
                                )}
                                isMobile={isMobile}
                                onSelect={(item) => {
                                  setShow("destination" + flight.id);
                                  setOriginLocationCode(item);
                                  handelUpdateFlightsData({
                                    id: flight.id,
                                    key: "origin",
                                    value: item,
                                  });
                                }}
                                show={show === "origin" + flight.id}
                                onClick={() => {
                                  setShow("origin" + flight.id);
                                  setFlightID(flight.id);
                                }}
                                selected={flight.origin?.cityName}
                                onClose={() =>
                                  handelUpdateFlightsData({
                                    id: flight.id,
                                    key: "origin",
                                    value: "",
                                  })
                                }
                              />
                              {flight?.origin && flight?.destination && (
                                <Box
                                  className="switch-icon"
                                  onClick={() => {
                                    const destination = flight.destination;

                                    handelUpdateFlightsData({
                                      id: flight.id,
                                      key: "destination",
                                      value: flight.origin,
                                    });

                                    handelUpdateFlightsData({
                                      id: flight.id,
                                      key: "origin",
                                      value: destination,
                                    });
                                  }}
                                >
                                  <SwitchLeftIcon />
                                </Box>
                              )}
                            </Box>
                            <Box className="block-group-item">
                              <BannerInputSection
                                placeholder="To"
                                toggleDrawer={toggleDrawer(
                                  true,
                                  "return-flight",
                                  flight.id
                                )}
                                isDestination
                                isMobile={isMobile}
                                onSelect={(item) => {
                                  setDestinationLocationCode(item);
                                  handelUpdateFlightsData({
                                    id: flight.id,
                                    key: "destination",
                                    value: item,
                                  });
                                  setShow("dateFrom");

                                  setFlightID(flight.id);
                                }}
                                show={show === "destination" + flight.id}
                                onClick={() => {
                                  setShow("destination" + flight.id);
                                  setFlightID(flight.id);
                                }}
                                selected={flight.destination?.cityName}
                                onClose={() =>
                                  handelUpdateFlightsData({
                                    id: flight.id,
                                    key: "destination",
                                    value: "",
                                  })
                                }
                              />
                            </Box>
                          </>
                        ) : (
                          <Box className="block-group-item">
                            <BannerInputSection
                              placeholder="Destination"
                              toggleDrawer={toggleDrawer(
                                true,
                                "return-flight",
                                flight.id
                              )}
                              isMobile={isMobile}
                              onSelect={(item) => {
                                setDestinationLocationCode(item);
                                handelUpdateFlightsData({
                                  id: flight.id,
                                  key: "destination",
                                  value: item,
                                });
                                setShow("dateFrom");

                                setFlightID(flight.id);
                              }}
                              show={show === "destination" + flight.id}
                              onClick={() => {
                                setShow("destination" + flight.id);
                                setFlightID(flight.id);
                              }}
                              selected={
                                flight.destination?.cityCode ||
                                flight.destination
                              }
                              onClose={() =>
                                handelUpdateFlightsData({
                                  id: flight.id,
                                  key: "destination",
                                  value: "",
                                })
                              }
                            />
                          </Box>
                        )}

                        <>
                          <div className="block-group-item calendar-date">
                            <BannerInput
                              placeholder={
                                activeTab === "flights"
                                  ? "Departure Date"
                                  : "Check In"
                              }
                              onFocus={() => {
                                setShow("dateFrom");
                                setFlightID(flight.id);
                              }}
                              name="dateFrom"
                              onClick={() => {
                                if (!isMobile) {
                                  setShow("dateFrom");
                                  setFlightID(flight.id);
                                }
                              }}
                              isMobile={isMobile}
                              toggleDrawer={toggleDrawer(
                                true,
                                "calendar",
                                flight.id,
                                "departure"
                              )}
                              value={
                                flight.originDate &&
                                moment(flight.originDate).format("MMM Do YY")
                              }
                              readOnly
                              required
                            />
                            {(show === "dateTo" || show === "dateFrom") &&
                              flightId === flight.id && (
                                <DatePicker
                                  isMobile={isMobile}
                                  toggleDrawer={toggleDrawer(false)}
                                  setdepartureDate={(date) => {
                                    handelUpdateFlightsData({
                                      id: flight.id,
                                      key: "originDate",
                                      value: date,
                                    });
                                  }}
                                  setReturnDate={setReturnDate}
                                  onSelect={(dates) => {
                                    handelUpdateFlightsData({
                                      id: flight.id,
                                      key: "originDate",
                                      value: dates.startDate,
                                    });
                                    handelUpdateFlightsData({
                                      id: flight.id,
                                      key: "destinationDate",
                                      value: dates.endDate,
                                    });
                                  }}
                                  onHide={() => {
                                    setShow(false);
                                  }}
                                  disabled={
                                    returnOption.currentOption !== "Return"
                                  }
                                  returnDate={flight?.destinationDate}
                                  departureDate={flight.originDate}
                                  show={show}
                                  setShow={setShow}
                                  flightType={returnOption.currentOption}
                                  dateDifference={moment(
                                    flight.originDate
                                  ).diff(
                                    moment(multiCity[index - 1]?.originDate),
                                    "days"
                                  )}
                                />
                              )}
                          </div>

                          {(returnOption.currentOption === "Return" ||
                            activeTab === "hotels") && (
                            <div className="block-group-item calendar-date">
                              <BannerInput
                                placeholder={
                                  activeTab === "flights"
                                    ? "Return"
                                    : "Check Out"
                                }
                                onFocus={() => {
                                  setShow("dateTo");
                                  setFlightID(flight.id);
                                }}
                                onClick={() => {
                                  if (!isMobile) {
                                    setShow("dateTo");
                                    setFlightID(flight.id);
                                  }
                                }}
                                isMobile={isMobile}
                                toggleDrawer={toggleDrawer(true, "calendar")}
                                value={
                                  flight.destinationDate
                                    ? moment(flight.destinationDate).format(
                                        "MMM Do YY"
                                      )
                                    : ""
                                }
                                readOnly
                                required
                                flightType={returnOption.currentOption}
                              />
                            </div>
                          )}
                        </>
                      </div>

                      {returnOption.currentOption === "Multi-city" ? (
                        multiCity.length > 2 && (
                          <IconButton
                            onClick={() => handleRemoveDestination(flight.id)}
                            sx={{
                              height: "40px",
                              mt: 0.5,
                              width: "40px",
                              ml: 1,
                              position: { xs: "absolute", md: "relative" },
                              right: { xs: "20px", md: "0px" },
                            }}
                            size="small"
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        )
                      ) : (
                        <button
                          disabled={isBtnValid}
                          className="search-btn btn"
                          onClick={redirectSearch}
                        >
                          Let’s go
                        </button>
                      )}
                    </div>
                  ))}
                </Box>
              </ClickAwayListener>

              {returnOption.currentOption === "Multi-city" && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={2}
                  flexWrap="wrap"
                >
                  <Button
                    disabled={multiCity.length >= 4}
                    variant="contained"
                    color="secondary"
                    onClick={handleAddDestination}
                    sx={{ width: { xs: "100%", md: "auto" } }}
                  >
                    Add Destination
                  </Button>

                  <button
                    disabled={isBtnValid}
                    className="search-btn btn"
                    onClick={redirectSearch}
                  >
                    Let’s go
                  </button>
                </Box>
              )}
            </>
          )}
        </Box>

        {loading && (
          <LinearProgress
            className="progress-bar-calendar"
            sx={{
              mb: 2,

              height: "2px",
              position: "absolute",
              width: "100%",
              bottom: "-16px",
              left: "0px",
            }}
            color="secondary"
          />
        )}
      </Box>
      <Modal
        className={`${
          (activeList === "departure" || activeList === "return-flight") &&
          "border-modal"
        } ${activeList === "calendar" && "full-size-modal"}`}
        toggleDrawer={toggleDrawer}
        openDrawer={openDrawer}
        sx={{
          "& .MuiDrawer-paperAnchorBottom": {
            minHeight: "260px",
          },
        }}
      >
        {list()}
      </Modal>
    </>
  );
};

export default Banner;
