import React from "react";
import Button from "../button";

import { BiMinus } from "react-icons/bi";
import { BsPlus } from "react-icons/bs";

const ListItem = ({
  title,
  subTitle,
  Icon,
  onClickMinus,
  onClickPlus,
  num,
  index,
  pass,
  onCLick,
  img,
}) => {
  return (
    <div
      onClick={onCLick}
      className={`item-flex mb-2 ${img ? "img-avatar" : "noImg"}`}
    >
      {Icon && <Icon />}
      <div className={`pass-users ${img ? "img-avatar" : "noImg"}`}>
        {img && <img className="results-card-logo logos-collapse" src={img} />}
        <p>{title}</p>
        {subTitle && <span>{subTitle}</span>}
      </div>
      {num !== undefined && (
        <div className="btn-group">
          <Button
            className="icon-box"
            disabled={num === 0}
            onClick={onClickMinus}
          >
            <BiMinus />
          </Button>
          <span>{num}</span>
          <Button className="icon-box" onClick={onClickPlus}>
            <BsPlus />
          </Button>
        </div>
      )}
    </div>
  );
};

export default ListItem;
