import React, { useState } from "react";
import SelectOptions from "../selectOptions";
import { useFormikContext } from "formik";
import dayjs from "dayjs";
import moment from "moment";

import "./style.css";
import { useEffect } from "react";

const CalendarField = ({
  label,
  mandatory,
  value,
  error1,
  error2,
  error3,
  onChangeDay,
  onChangeMonth,
  onChangeYear,
  nameDay,
  nameMonth,
  nameYear,
  valueDay,
  valueMonth,
  valueYear,
  onBlurDay,
  onBlurMonth,
  onBlurYear,
  readOnly = false,
  departureDate,
  index,
  travelerType,
}) => {
  const { setFieldValue, setFieldError, values, errors } = useFormikContext();

  const [data, setData] = useState({
    monthValue: "",
    dayValue: "",
    yearValue: "",
  });

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthsLong = {
    January: "01",
    February: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12",
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }
  // const handleCheckDepartureonChange = () => {
  //   setTimeout(() => {
  //     if (departureDate && data.dayValue && data.monthValue && data.yearValue) {
  //       let isValid = dayjs(
  //         `${data.dayValue}-${data.monthValue}-${data.yearValue}`
  //       ).diff(dayjs(departureDate), "month");

  //       if (isValid < 6) {
  //         setFieldError(nameDay, "Expiration Passport Date is Invalid");
  //       } else {
  //         setFieldError(nameDay, undefined);
  //       }
  //     }
  //   }, 600);
  // };

  const handelValidateDateOfBirth = () => {
    const year = values["dateOfBirthYear" + index];
    if (
      index !== undefined &&
      values["dateOfBirthDay" + index] &&
      values["dateOfBirthMonth" + index] &&
      year
    ) {
      const month =
        monthsLong[values["dateOfBirthMonth" + index]] ||
        values["dateOfBirthMonth" + index];
      const day = values["dateOfBirthDay" + index];
      const dur = moment.duration({
        from: `${year}-${month}-${day}`,
        to: moment(),
      });

      const duration = dur.asMonths().toFixed(0);

      setTimeout(() => {
        if (travelerType === "CHILD" && (duration < 24 || duration > 132)) {
          setFieldError(nameDay, "Child should be 2-11");
        } else if (travelerType === "SENIOR" && duration < 720) {
          setFieldError(nameDay, "Senior should be 60+");
        } else if (travelerType === "ADULT" && duration < 132) {
          setFieldError(nameDay, "Adult should be over 11");
        } else if (travelerType === "HELD_INFANT" && duration > 24) {
          setFieldError(nameDay, "Infant should be under 2");
        } else {
          setFieldError(nameDay, undefined);
        }
      }, 600);
    }
  };

  // useEffect(() => {
  //   if (departureDate) {
  //     handleCheckDepartureonChange();
  //   }
  // }, [data, values]);

  useEffect(() => {
    handelValidateDateOfBirth();
  }, [values["dateOfBirthMonth" + index], values["dateOfBirthYear" + index]]);

  return (
    <div className="input-group-container">
      {label && (
        <label>
          {mandatory && <span className="mandatory-sign">*</span>}
          {label}
        </label>
      )}
      <div
        className={`input-wapper ${(error1 || error2 || error3) && "error"}`}
      >
        <div className="date-picker-container-css">
          <input
            onChange={(e) => {
              setData({ ...data, dayValue: e.target.value });

              onChangeDay(e.target.value);
            }}
            name={nameDay}
            placeholder="DD"
            onBlur={onBlurDay}
            value={valueDay}
            type="number"
            readOnly={readOnly}
            inputMode="numeric"
            max={31}
            min={1}
          />
          <SelectOptions
            options={months}
            placeholder="Month"
            value={getKeyByValue(monthsLong, valueMonth)}
            name={nameMonth}
            onChange={(e) => {
              setData({ ...data, monthValue: e.target.value });
              onChangeMonth(e.target.value);
            }}
            onBlur={onBlurMonth}
            disabled={readOnly}
          />
          <input
            onChange={(e) => {
              setData({ ...data, yearValue: e.target.value });
              onChangeYear(e.target.value);
            }}
            name={nameYear}
            placeholder="YYYY"
            onBlur={onBlurYear}
            value={valueYear}
            type="number"
            readOnly={readOnly}
            pattern="[0-9]*"
            inputMode="numeric"
            min={1}
          />
        </div>
      </div>
      {(error1 || error2 || error3) && (
        <span className="error-msg">
          {error1
            ? error1
            : error2
            ? "Month Field is required"
            : error3
            ? label === "Date of birth"
              ? "Date of birth is invalid"
              : "Expiration Passport Date is invalid"
            : ""}
        </span>
      )}
    </div>
  );
};

export default CalendarField;
