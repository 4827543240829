import React, { useState } from "react";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import Divider from "@mui/material/Divider";
import ListItemCustom from "components/listItem";

import Modal from "../../swipeDrawer";
import MainButton from "../../button";
import RoomAndTravelers from "../search-tabs/components/rooms&travelers";

import HotelsTab from "../search-tabs/hotels-tab";
import DropDown from "../../dropDown";
import { Typography } from "@mui/material";

//----------------------------------------------------------------

const HotelV2Tab = ({
  rooms,
  setRooms,
  amenities,
  setAmenities,
  rating,
  setRating,
}) => {
  const [show, setShow] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (open, type) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShow(type);
    setOpenDrawer(open);
  };

  const handelAmenities = (item) => {
    setAmenities((prevState) => {
      const { selected } = prevState;
      const selectedAmenities = selected ? selected.split(",") : [];

      // Check if the item value already exists in the selected amenities
      const updatedAmenities = selectedAmenities.includes(item.value)
        ? selectedAmenities.filter((val) => val !== item.value) // Remove the amenity if it exists
        : [...selectedAmenities, item.value]; // Add the amenity if it doesn't exist

      return {
        ...prevState,
        selected: updatedAmenities.join(","),
      };
    });
  };

  const handelRatings = (item) => {
    setRating((prevState) => {
      const { selected } = prevState;
      const selectedValues = selected ? selected.split(",") : [];

      // Check if the item value already exists in the selected values
      const updatedValues = selectedValues.includes(item.value)
        ? selectedValues.filter((val) => val !== item.value) // Remove the value if it exists
        : [...selectedValues, item.value]; // Add the value if it doesn't exist

      return {
        ...prevState,
        selected: updatedValues.join(","),
      };
    });
  };

  const getNum = (value) => {
    const num = rooms.find((room) => room.key === value)?.num;
    return num;
  };

  const onClickPlus = (value) => {
    setRooms((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.value === value) {
          return { ...obj, num: obj.num + 1 };
        }

        // 👇️ otherwise return object as is
        return obj;
      });

      return newState;
    });
  };

  const onClickMinus = (value) => {
    setRooms((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.value === value && obj.num > 1) {
          return { ...obj, num: obj.num - 1 };
        }

        // 👇️ otherwise return object as is
        return obj;
      });

      return newState;
    });
  };

  const list = () => (
    <Box
      sx={{
        width: "auto",
        height: "auto",
      }}
      display="flex"
      flexDirection="column"
      role="presentation"
    >
      {show === "filter" ? (
        <>
          <List>
            <Typography ml={2} mt={2} type="subtitle2" fontWeight="500">
              Amenities
            </Typography>
            {amenities.options.map((item, index) => (
              <ListItem
                onClick={() => {
                  handelAmenities(item);
                }}
                key={index}
              >
                <ListItemText primary={item.value} />
                <Radio
                  checked={amenities.selected?.includes(item.value)}
                  color="default"
                />
              </ListItem>
            ))}
          </List>

          <Divider />

          <List>
            <Typography ml={2} mt={2} type="subtitle2" fontWeight="500">
              Ratings
            </Typography>
            {rating.options.map((item, index) => (
              <ListItem
                onClick={() => {
                  handelRatings(item);
                }}
                key={index}
              >
                <ListItemText primary={item.value} />
                <Radio
                  checked={rating.selected?.includes(item.value)}
                  color="default"
                />
              </ListItem>
            ))}
          </List>
        </>
      ) : (
        <List>
          <Typography ml={2} mt={2} mb={2} type="subtitle2" fontWeight="500">
            {getNum("adult")} Travelers, {getNum("rooms")} Room
          </Typography>
          <Box mx={2} gap={2}>
            {rooms.map((item, index) => (
              <ListItemCustom
                key={index}
                index={index}
                title={item?.value}
                subTitle={item?.subTitle}
                num={item?.num}
                onClickMinus={() => onClickMinus(item.value)}
                onClickPlus={() => onClickPlus(item.value)}
                pass
              />
            ))}
          </Box>
        </List>
      )}

      <Divider />
      <div className="btns-wrapper">
        <MainButton className="bigBtn" onClick={toggleDrawer(false)}>
          Cancel
        </MainButton>
        <MainButton
          onClick={() => {
            toggleDrawer(false)();
          }}
          className="bigBtn primary-color"
        >
          Done
        </MainButton>
      </div>
    </Box>
  );

  return (
    <>
      <HotelsTab rooms={rooms} setRooms={setRooms} isSearchable />

      <DropDown
        title="Amenities"
        options={amenities.options}
        activeOption={amenities.selected}
        multiple
        onClick={(item) => handelAmenities(item)}
      />

      <DropDown
        title="Ratings"
        options={rating.options}
        activeOption={rating.selected}
        multiple
        onClick={(item) => handelRatings(item)}
      />

      <div className="mobile-view">
        <Button onClick={toggleDrawer(true, "room")}>
          {" "}
          {getNum("adult")} Travelers, {getNum("rooms")} Room
        </Button>
        <Button onClick={toggleDrawer(true, "filter")}>Filters</Button>
      </div>

      <Modal
        className={"border-modal"}
        toggleDrawer={toggleDrawer}
        openDrawer={openDrawer}
        sx={{
          "& .MuiDrawer-paperAnchorBottom": {
            minHeight: "260px",
          },
        }}
      >
        {list()}
      </Modal>
    </>
  );
};

export default HotelV2Tab;
