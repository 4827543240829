import React, { useState } from "react";

import DropDown from "../../dropDown";
import RoomAndTravelers from "./components/rooms&travelers";

function HotelsTab({
  rooms,
  setRooms,
  infoSource,
  setInfoSource,
  isSearchable,
}) {
  const onClickPlus = (value) => {
    setRooms((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.value === value) {
          return { ...obj, num: obj.num + 1 };
        }

        // 👇️ otherwise return object as is
        return obj;
      });

      return newState;
    });
  };

  const onClickMinus = (value) => {
    setRooms((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.value === value && obj.num > 1) {
          return { ...obj, num: obj.num - 1 };
        }

        // 👇️ otherwise return object as is
        return obj;
      });

      return newState;
    });
  };

  const getNum = (value) => {
    const num = rooms.find((room) => room.key === value)?.num;
    return num;
  };

  const handelChangeChildAge = (value, index) => {
    setRooms((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.key === "children") {
          const updatedChildAge = [...obj.childAge];
          if (updatedChildAge[index] !== undefined) {
            updatedChildAge[index] = value;
          } else {
            updatedChildAge.push(value);
          }

          return { ...obj, childAge: updatedChildAge };
        }

        return obj;
      });

      return newState;
    });
  };

  return (
    <>
      <RoomAndTravelers
        activeOption={`${
          getNum("adult") + (getNum("children") || 0)
        }  Travelers, ${getNum("rooms")} Room`}
        options={rooms}
        onClickPlus={onClickPlus}
        onClickMinus={onClickMinus}
        handelChangeChildAge={handelChangeChildAge}
      />
      {!isSearchable && (
        <DropDown
          options={infoSource.options}
          activeOption={infoSource.selected}
          onClick={(item) =>
            setInfoSource((prevState) => ({
              ...prevState,
              selected: item.value,
            }))
          }
        />
      )}
    </>
  );
}

export default HotelsTab;
