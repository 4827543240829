import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";

import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import { Box } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";

import Button from "components/button";

import { BiMinus } from "react-icons/bi";
import { BsPlus } from "react-icons/bs";
import { TextField } from "@mui/material";
export default function DropDown({
  activeOption,
  options,
  onClickMinus,
  onClickPlus,
  handelChangeChildAge,
}) {
  return (
    <Menu
      className="main-dropdown"
      menuButton={
        <MenuButton className={`dropdown-btn`}>
          {activeOption}
          <IoIosArrowDown />
        </MenuButton>
      }
      transition
    >
      <div className="passenger-container p-3">
        <h2> {activeOption}</h2>
        <div className="mb-24">
          {options.map((item, index) => (
            <Box className={`item-flex mb-2 "noImg`} alignItems={"baseline"}>
              <div className={`pass-users "noImg`}>
                <p>{item.value}</p>
              </div>
              <Box>
                {item.value === "Children" && item.num > 0 && (
                  <>
                    {Array.from({ length: item.num }).map((_, i) => (
                      <Box
                        width={"80px"}
                        sx={{ mx: 1 }}
                        height={"30px"}
                        key={i}
                        mb={1.2}
                      >
                        <TextField
                          variant="outlined"
                          size="small"
                          required
                          type="number"
                          onChange={(e) =>
                            handelChangeChildAge(e.target.value, i)
                          }
                          inputProps={{ min: 1, step: 1 }}
                          placeholder={"Age " + (i + 1)}
                          sx={{
                            "& input": { pt: 0.6, pb: 0.6 },
                            boxShadow: "rgb(186 199 213) 0px 0px 0px 1px",
                            borderRadius: "5px",
                            fontSize: "12px",
                            "& input::placeholder": {
                              fontSize: "14px",
                            },
                          }}
                        />
                      </Box>
                    ))}
                  </>
                )}
              </Box>
              {item.num !== undefined && (
                <div className="btn-group">
                  <Button
                    className="icon-box"
                    disabled={item.num === 0}
                    onClick={() => onClickMinus(item.value)}
                  >
                    <BiMinus />
                  </Button>
                  <span>{item.num}</span>
                  <Button
                    className="icon-box"
                    onClick={() => onClickPlus(item.value)}
                  >
                    <BsPlus />
                  </Button>
                </div>
              )}
            </Box>
          ))}
        </div>
      </div>
      <MenuItem className="d-block footer-drop">
        <hr />
        <div className="d-flex justify-content-between">
          <Button>Cancel</Button>
          <Button className="primary-color">Done</Button>
        </div>
      </MenuItem>
    </Menu>
  );
}
